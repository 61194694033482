import { Autocomplete, MenuItem, TextField } from "@mui/material";
import { Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow, useEdges } from "reactflow";

const statusToColorMapper = {
    Success: "#9cff9c",
    Error: "#ff8686",
    Completed: "#ffeaa1",
};

export default function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}) {
    const { setEdges } = useReactFlow();
    const edges = useEdges();
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const orchestration = useSelector((state) => state.orchestration);

    const handleEdgeInputChange = (newValue) => {
        setEdges((edges) => {
            edges.forEach((edge) => {
                if (edge.id === id) {
                    edge.edgeStatus = newValue;
                }
            });
            return edges;
        });
    };

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: "absolute",
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: "all",
                    }}
                    className="nodrag nopan"
                >
                    {orchestration.orchestrationMode === "RUNS" ? (
                        <>
                            <div
                                style={{
                                    width: 100,
                                    borderRadius: 16,
                                    border: "1px solid #515151",
                                    backgroundColor: statusToColorMapper[edges.filter((edge) => edge.id === id)[0]?.edgeStatus],
                                    padding: "5px 5px",
                                    textAlign: "center",
                                }}
                            >
                                <Typography>{edges.filter((edge) => edge.id === id)[0]?.edgeStatus}</Typography>
                            </div>
                        </>
                    ) : (
                        <Autocomplete
                            options={["Success", "Error", "Completed"]}
                            value={edges.filter((edge) => edge.id === id)[0]?.edgeStatus}
                            fullWidth
                            sx={{
                                "& fieldset": { borderRadius: 16 },
                                "& .MuiOutlinedInput-root": {
                                    paddingRight: "20px !important",
                                },
                                width: 100,
                            }}
                            onChange={(e, newValue) => handleEdgeInputChange(newValue)}
                            clearIcon={false}
                            size="small"
                            clearOnBlur={true}
                            disableClearable={true}
                            renderInput={(params) => (
                                <TextField
                                    style={{
                                        backgroundColor:
                                            statusToColorMapper[edges.filter((edge) => edge.id === id)[0]?.edgeStatus],
                                        borderRadius: 16,
                                    }}
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                />
                            )}
                        />
                    )}
                </div>
            </EdgeLabelRenderer>
        </>
    );
}
