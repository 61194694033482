import { Grid, TextField, Typography, Switch, Button } from "@mui/material";
import { Autocomplete } from "@mui/material";
import NodeNotifications from "../common/NodeNotifications";
import React from "react";
import NodeTooltip from "../common/NodeTooltip";

export default function NodeConfigration({ onNodeExecutionConfigrationChange, onFinish, configuration }) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container style={{ border: "1px solid #EAEAEA", borderRadius: 15 }}>
                    <Grid style={{ padding: 10 }} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="blackText size-14 semiBoldText">Decision Settings</Typography>
                        </Grid>
                        <Grid item xs={11.5}>
                            <Autocomplete
                                options={[">", "<", "=", ">=", "<="]}
                                isOptionEqualToValue={(option, value) => value === option}
                                onChange={(e, newValue, reason) =>
                                    onNodeExecutionConfigrationChange("rowsMoved", configuration?.rowsMoved?.value, newValue)
                                }
                                value={configuration?.rowsMoved?.operator}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField {...params} size="small" label="Operator" variant="outlined" />
                                )}
                            />
                        </Grid>
                        <Grid xs={0.5}>
                            <NodeTooltip title="Operator to be used for comparison." />
                        </Grid>
                        <Grid item xs={11.5}>
                            <TextField
                                size="small"
                                label="Rows Moved"
                                variant="outlined"
                                fullWidth
                                value={configuration?.rowsMoved?.value}
                                onChange={(e) =>
                                    onNodeExecutionConfigrationChange(
                                        "rowsMoved",
                                        e.target.value,
                                        configuration?.rowsMoved?.operator
                                    )
                                }
                            />
                        </Grid>
                        <Grid xs={0.5}>
                            <NodeTooltip title="Number of rows to be moved." />
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid container style={{ border: "1px solid #EAEAEA", borderRadius: 10, marginTop: 15 }}>
                    <Grid style={{ padding: 10 }} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="blackText size-14 semiBoldText">Notification Settings</Typography>
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                            <Typography className="blackText semiBoldText">Slack</Typography>
                            <Switch
                                color="primary"
                                checked={configuration?.slackNotification?.value}
                                onChange={(e) => onNodeExecutionConfigrationChange("slackNotification", e.target.checked, "=")}
                                name="slack"
                            />
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                            <Typography className="blackText semiBoldText">Email</Typography>

                            <Switch
                                color="primary"
                                checked={configuration?.emailNotification?.value}
                                onChange={(e) => onNodeExecutionConfigrationChange("emailNotification", e.target.checked, "=")}
                                name="slack"
                            />
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                    <Button onClick={onFinish} variant="contained" color="primary">
                        Finish
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
