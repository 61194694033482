import { boolean, string, array, mixed } from "yup";

export const getSchedulingValidation = (getLevel) => ({
    manualRun: boolean(),
    schedule: string()
        .when("manualRun", {
            is: false,
            then: string().required("This is a required field.").nullable(),
        })
        .nullable(),
    hour: string().when(["schedule", "manualRun"], {
        is: (schedule, manualRun) =>
            (getLevel(schedule) === 2 || getLevel(schedule) === 3 || getLevel(schedule) === 4) && manualRun === false,
        then: string().required(),
    }),
    start_hour: string().when(["schedule", "manualRun"], {
        is: (schedule, manualRun) => getLevel(schedule) === 5 && manualRun === false,
        then: string().required("This is a required field."),
    }),
    day: string().when(["schedule", "manualRun"], {
        is: (schedule, manualRun) => (getLevel(schedule) === 3 || getLevel(schedule) === 4) && manualRun === false,
        then: string().required(),
    }),
    // month: string().when(['schedule', 'manualRun'], {
    //     is: (schedule, manualRun) => getLevel(schedule) === 4 && manualRun === false,
    //     then: string().required()
    // }),
    months: mixed().when(["schedule", "manualRun"], {
        is: (schedule, manualRun) => (getLevel(schedule) === 5 || getLevel(schedule) === 6) && manualRun === false,
        then: array().min(1, "This is a required field."),
    }),
    days: mixed().when(["schedule", "manualRun"], {
        is: (schedule, manualRun) => (getLevel(schedule) === 5 || getLevel(schedule) === 6) && manualRun === false,
        then: array().min(1, "This is a required field."),
    }),
    interval: string().when(["schedule", "manualRun"], {
        is: (schedule, manualRun) => getLevel(schedule) === 5 && manualRun === false,
        then: string().required(),
    }),
    minute: string().when(["schedule", "manualRun"], {
        is: (schedule, manualRun) => manualRun === false && (getLevel(schedule) === 5 || getLevel(schedule) === 6),
        then: string().required(),
    }),
    hours: mixed().when(["schedule", "manualRun"], {
        is: (schedule, manualRun) => manualRun === false && getLevel(schedule) === 6,
        then: array().min(1, "This is a required field."),
    }),
});
