import React from "react";
import useApi from "hooks/useApi";
import { Autocomplete, CircularProgress, TextField, Typography, Grid, Button } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import NodeNotifications from "../common/NodeNotifications";

const filterOptions = (options, state) => {
    const newOptions = [...options].filter((op) => op.FunctionName.toLowerCase().includes(state.inputValue.toLowerCase()));
    return newOptions;
};

export default function SelectLambdaFunction({ onNodeExecutionConfigrationChange, onFinish, configuration }) {
    const [, dynamicDataLoading, getDynamicParamData] = useApi();

    const [functions, setFunctions] = React.useState([]);
    const [jsonValidated, setJsonValidated] = React.useState(false);

    const getAllWorkbooks = async () => {
        const userData = {
            credential_id: configuration?.credential?.value?.id,
        };
        let data = await getDynamicParamData({
            module: "orchestration",
            method: "getOrcehstrationDynamicParamData",
            apiData: {
                node_slug: "awsLambdaFunction",
                property_name: "function_name",
                data: userData,
            },
            returnResult: true,
        });
        setFunctions(data);
    };
    React.useEffect(() => {
        getAllWorkbooks();
    }, []);
    const handleFinish = () => {
        try {
            setJsonValidated(false);
            let x = JSON.parse(configuration?.payload?.value);
            onFinish();
        } catch (e) {
            console.log(e);
            setJsonValidated(true);
            return;
        }
    };
    return (
        <>
            {dynamicDataLoading ? (
                <Grid container justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    <Grid style={{ margin: "18px 0px 0px", padding: "15px 15px 0px" }}>
                        <Typography className="blackText size-14 semiBoldText">Select Lambda Function Name</Typography>
                        <Autocomplete
                            options={functions}
                            filterOptions={filterOptions}
                            isOptionEqualToValue={(option, value) => value.FunctionName === option.FunctionName}
                            getOptionLabel={(option) => option.FunctionName}
                            getOptionKey={(option) => option.FunctionName}
                            onChange={(e, newValue, reason) => onNodeExecutionConfigrationChange("function_name", newValue, "=")}
                            value={configuration?.function_name?.value}
                            renderInput={(params) => (
                                <TextField {...params} size="small" label="Select Lambda Function Name" variant="outlined" />
                            )}
                        />
                    </Grid>
                    <Grid style={{ margin: "18px 0px 0px", padding: "15px 15px 0px" }}>
                        <Typography className="blackText size-14 semiBoldText">Json Payload For Lambda</Typography>
                        <TextField
                            id="json-payload"
                            label="JSON Payload"
                            multiline
                            value={configuration?.payload?.value}
                            onChange={(e) => onNodeExecutionConfigrationChange("payload", e.target.value, "=")}
                            fullWidth
                            rows={10}
                            defaultValue={null}
                            variant="outlined"
                        />
                        {jsonValidated && (
                            <Typography variant="h6" className="errorText">
                                Please enter a valid json payload.
                            </Typography>
                        )}
                    </Grid>

                    <NodeNotifications
                        onNodeExecutionConfigrationChange={onNodeExecutionConfigrationChange}
                        value={configuration?.notification_level?.value}
                    />
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Button onClick={handleFinish} variant="contained" color="primary">
                                Finish
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
