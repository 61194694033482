import React from "react";
import { Typography, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NoData = ({ showButton, link, height, componentType, routeData, preCheck }) => {
	const navigate = useNavigate();
	const onClickHandler = () => {
		if (preCheck) {
			if (preCheck()){
				return
			}
			
		}
		navigate(link, routeData)
	}
	return (
        <Grid container justifyContent="center" alignItems="center" style={{ height: height, width: "100%" }}>
			<Grid item>
				<Typography variant="subtitle1">
					No {componentType} available. Please add a {componentType} to continue.
				</Typography>
				{showButton ? (
					<Grid container justifyContent="center">
						<Button
							size="small"
							color="primary"
							variant="contained"
							onClick={onClickHandler}
						>
							Add {componentType}
						</Button>
					</Grid>
				) : null}
			</Grid>
		</Grid>
    );
};

export default NoData;
