import React, { Suspense } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import TopBar from "./TopBar";
import { Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "calc(100vh - 94px)",
        width: "100%",
        marginTop: 95,
        overflowY: "hidden",
        overflowX: "hidden",
    },
    withoutHeader: {
        minHeight: "100vh",
        width: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
        backgroundColor: "#FFFFFF",
    },
    container: {
        display: "flex",
        backgroundColor: "#E5E5E5",
    },
    pageContainer: {
        backgroundColor: "#FAFAFA",
    },
}));

function FallBack() {
    return (
        <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh", width: "100vw" }}>
            <CircularProgress />
        </Grid>
    );
}

export default function Main(props) {
    const classes = useStyles();
    return (
        <>
            <Container maxWidth={false} disableGutters className={classes.pageContainer}>
                <TopBar pageTitle={props.pageTitle} hideSearch={true} fullWidth subscription={true} />
                <div className={classes.container}>
                    {/* <motion.div
                        initial={{ opacity: 0, x: "-100vh" }}
                        animate={{ opacity: 1, x: 0 }}
                    > */}
                    <Suspense fallback={<FallBack />}>
                        <div
                            className={clsx({
                                [classes.root]: props.pageTitle !== "homepage",
                                [classes.withoutHeader]: props.pageTitle === "homepage",
                            })}
                        >
                            <props.comp {...props} />
                        </div>
                    </Suspense>
                    {/* </motion.div> */}
                </div>
            </Container>
        </>
    );
}
