import { Grid, TextField, Typography, Switch, Button } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React from "react";
import NodeNotifications from "../common/NodeNotifications";
import NodeTooltip from "../common/NodeTooltip";

export default function NodeConfigration({ onNodeExecutionConfigrationChange, onFinish, configuration, handleBack }) {
    React.useEffect(() => {
        if (configuration?.retryCount?.value === undefined) {
            onNodeExecutionConfigrationChange("retryCount", "1", "=");
        }
    }, []);
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container style={{ border: "1px solid #EAEAEA", borderRadius: 15 }}>
                    <Grid style={{ padding: 10 }} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="blackText size-14 semiBoldText">Number of tries</Typography>
                        </Grid>
                        <Grid item xs={11.5}>
                            <Autocomplete
                                options={["1", "2", "3"]}
                                defaultValue="1"
                                isOptionEqualToValue={(option, value) => value === option}
                                onChange={(e, newValue, reason) => onNodeExecutionConfigrationChange("retryCount", newValue, "=")}
                                value={configuration?.retryCount?.value}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField {...params} size="small" label="Number of tries" variant="outlined" />
                                )}
                            />
                        </Grid>
                        <Grid xs={0.5}>
                            <NodeTooltip title="Number of times the node will be retried in case of failure." />
                        </Grid>
                        <Grid item xs={11.5}>
                            <Autocomplete
                                options={["10", "20", "30"]}
                                isOptionEqualToValue={(option, value) => value === option}
                                onChange={(e, newValue, reason) =>
                                    onNodeExecutionConfigrationChange("retryDuration", newValue, "=")
                                }
                                value={configuration?.retryDuration?.value}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField {...params} size="small" label="Retry Interval (minutes)" variant="outlined" />
                                )}
                            />
                        </Grid>
                        <Grid xs={0.5}>
                            <NodeTooltip title="Time interval between each retry." />
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid container style={{ border: "1px solid #EAEAEA", borderRadius: 10, marginTop: 15 }}>
                    <Grid style={{ padding: 10 }} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="blackText size-14 semiBoldText">Notification Settings</Typography>
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                            <Typography className="blackText semiBoldText">Slack</Typography>
                            <Switch
                                color="primary"
                                checked={configuration?.slackNotification?.value}
                                onChange={(e) => onNodeExecutionConfigrationChange("slackNotification", e.target.checked, "=")}
                                name="slack"
                            />
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                            <Typography className="blackText semiBoldText">Email</Typography>

                            <Switch
                                color="primary"
                                checked={configuration?.emailNotification?.value}
                                onChange={(e) => onNodeExecutionConfigrationChange("emailNotification", e.target.checked, "=")}
                                name="slack"
                            />
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid xs={12}>
                    <NodeNotifications
                        onNodeExecutionConfigrationChange={onNodeExecutionConfigrationChange}
                        value={configuration?.notification_level?.value}
                    />
                </Grid>
                <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                    {/* <Button onClick={handleBack} variant="outlined" color="primary">
                        Back
                    </Button> */}
                    <Button onClick={onFinish} variant="contained" color="primary">
                        Finish
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
