
import { WORKSPACE_OVERALL_RUN_STATS, WORKSPACE_OVERALL_STATS, WORKSPACE_OVERALL_RUN_STATS_IS_LOADING, WORKSPACE_OVERALL_STATS_IS_LOADING, WORKSPACE_ACCOUNT_USERS, WORKSPACE_ACCOUNT_USERS_IS_LOADING, ACCOUNT_WORKSPACES, ACCOUNT_WORKSPACES_IS_LOADING, ACCOUNT_ROWS_MOVED, ACCOUNT_ROWS_MOVED_LOADING, WORKSPACE_DATASOURCE_STATS, WORKSPACE_DATASOURCE_STATS_IS_LOADING, WORKSPACE_DATA_MOVED_BY_DATE, WORKSPACE_DATA_MOVED_BY_DATE_IS_LOADING, WORKSPACE_DESTINATION_STATS, WORKSPACE_DESTINATION_STATS_IS_LOADING, WORKSPACE_REVERSE_ROWS_MOVED_BY_DATE, WORKSPACE_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING, WORKSPACE_ROWS_MOVED_BY_DATE, WORKSPACE_ROWS_MOVED_BY_DATE_IS_LOADING } from "../actionTypes/actionTypes";

import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";

const fetchWorkspaceOverAllStats = (data, workspace_id) => async (dispatch) => {

    apiWrapper(
        dispatch,
        WORKSPACE_OVERALL_STATS_IS_LOADING,
        WORKSPACE_OVERALL_STATS,
        {
            module: "workspace",
            method: "getWorkspaceOverallStats",
            apiData: {
                data: data,
                workspace_id: workspace_id
            },
        },
        HttpErrorHandler
    );


};

const fetchWorkspaceOverAllRunStats = (data, workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        WORKSPACE_OVERALL_RUN_STATS_IS_LOADING,
        WORKSPACE_OVERALL_RUN_STATS,
        {
            module: "workspace",
            method: "getWorkspaceOverAllRunStats",
            apiData: {
                data: data,
                workspace_id: workspace_id
            },
        },
        HttpErrorHandler
    );
};
const fetchAccountUser = (workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        WORKSPACE_ACCOUNT_USERS_IS_LOADING,
        WORKSPACE_ACCOUNT_USERS,
        {
            module: "workspace",
            method: "getAccountUser",
            apiData: {
                workspace_id: workspace_id
            },
        },
        HttpErrorHandler
    );
}

const fetchAccountWorkspaces = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        ACCOUNT_WORKSPACES_IS_LOADING,
        ACCOUNT_WORKSPACES,
        {
            module: "workspace",
            method: "getAccountWorkspaces",
        },
        HttpErrorHandler
    );



};
const fetchAccountRowsMoved = (data) => async (dispatch) => {
    apiWrapper(
        dispatch,
        ACCOUNT_ROWS_MOVED_LOADING,
        ACCOUNT_ROWS_MOVED,
        {
            module: "workspace",
            method: "getAccountsRowsMoved",
            apiData: {
                data: data
            },
        },

    )
}

const fetchWorkspaceDatasourceStats = (data,workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        WORKSPACE_DATASOURCE_STATS_IS_LOADING,
        WORKSPACE_DATASOURCE_STATS,
        {
            module: "workspace",
            method: "getDatasourceStats",
            apiData: {
                data: data,
                workspace_id:workspace_id
            },
        },
        HttpErrorHandler
    );
};

const fetchWorkspaceReverseConnectorDashboardData = (data,workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        WORKSPACE_DESTINATION_STATS_IS_LOADING,
        WORKSPACE_DESTINATION_STATS,
        {
            module: "workspace",
            method: "getReverseConnectorDashboardData",
            apiData: {
                data: data,
                workspace_id:workspace_id

            },
        },
        HttpErrorHandler
    );
};
const fetchWorkspaceRowsMovedByDate = (data,workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        WORKSPACE_ROWS_MOVED_BY_DATE_IS_LOADING,
        WORKSPACE_ROWS_MOVED_BY_DATE,
        {
            module: "workspace",
            method: "getRowsMovedByDate",
            apiData: {
                data: data,
                workspace_id:workspace_id

            },
        },
        HttpErrorHandler
    );
};

const fetchWorkspaceDataMovedByDate = (data,workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        WORKSPACE_DATA_MOVED_BY_DATE_IS_LOADING,
        WORKSPACE_DATA_MOVED_BY_DATE,
        {
            module: "workspace",
            method: "getDataMovedByDate",
            apiData: {
                data: data,
                workspace_id:workspace_id

            },
        },
        HttpErrorHandler
    );
};

const fetchWorkspaceReverseRowsMovedByDate = (data,workspace_id) => async (dispatch) => {
    apiWrapper(
        dispatch,
        WORKSPACE_REVERSE_ROWS_MOVED_BY_DATE_IS_LOADING,
        WORKSPACE_REVERSE_ROWS_MOVED_BY_DATE,
        {
            module: "workspace",
            method: "getReverseRowsMovedByDate",
            apiData: {
                data: data,
                workspace_id:workspace_id

            },
        },
        HttpErrorHandler
    );
};



export {
    fetchWorkspaceOverAllStats,
    fetchWorkspaceOverAllRunStats,
    fetchAccountUser,
    fetchAccountWorkspaces,
    fetchAccountRowsMoved,
    fetchWorkspaceDataMovedByDate,
    fetchWorkspaceDatasourceStats,
    fetchWorkspaceReverseConnectorDashboardData,
    fetchWorkspaceRowsMovedByDate,
    fetchWorkspaceReverseRowsMovedByDate
};
