import { Grid, TextField, Typography, Switch, Button } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import useApi from "hooks/useApi";
import NodeTooltip from "../common/NodeTooltip";
import NodeNotifications from "../common/NodeNotifications";
/**
 * custom hook for forceupdate. used with simple-react-validator
 */
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
}

export default function ModalNodeConfiguration({ onNodeExecutionConfigrationChange, onFinish, configuration }) {
    console.log(configuration);
    const forceUpdate = useForceUpdate();
    const validator = useRef(new SimpleReactValidator()).current;

    const handleFinish = () => {
        if (validator.allValid()) {
            onFinish();
        } else {
            validator.showMessages();
            forceUpdate();
        }
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container style={{ border: "1px solid #EAEAEA", borderRadius: 15 }}>
                    <Grid style={{ padding: 10 }} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="blackText size-14 semiBoldText">Tablue Configration</Typography>
                        </Grid>
                        <Grid item xs={11.5}>
                            <TextField
                                size="small"
                                label="Personal Access Token Name"
                                variant="outlined"
                                fullWidth
                                value={configuration?.personal_access_token_name?.value}
                                onChange={(e) => onNodeExecutionConfigrationChange("personal_access_token_name", e.target.value)}
                            />
                            <Typography variant="h6" style={{ color: "red" }}>
                                {validator.message(
                                    "Personal Access Token Name",
                                    configuration?.personal_access_token_name?.value,
                                    "required"
                                )}
                            </Typography>
                        </Grid>
                        <Grid xs={0.5}>
                            <NodeTooltip title="Your personal access token name" />
                        </Grid>
                        <Grid item xs={11.5}>
                            <TextField
                                size="small"
                                label="Personal Access Token Secret"
                                variant="outlined"
                                fullWidth
                                value={configuration?.personal_access_token_secret?.value}
                                onChange={(e) =>
                                    onNodeExecutionConfigrationChange("personal_access_token_secret", e.target.value)
                                }
                            />
                            <Typography variant="h6" style={{ color: "red" }}>
                                {validator.message(
                                    "Personal Access Token Secret",
                                    configuration?.personal_access_token_secret?.value,
                                    "required"
                                )}
                            </Typography>
                        </Grid>
                        <Grid xs={0.5}>
                            <NodeTooltip title="Your personal access token secret" />
                        </Grid>
                        <Grid item xs={11.5}>
                            <TextField
                                size="small"
                                label="Site Name"
                                variant="outlined"
                                fullWidth
                                value={configuration?.site_name?.value}
                                onChange={(e) => onNodeExecutionConfigrationChange("site_name", e.target.value)}
                            />
                            <Typography variant="h6" style={{ color: "red" }}>
                                {validator.message("Site Name", configuration?.site_name?.value, "required")}
                            </Typography>
                        </Grid>
                        <Grid xs={0.5}>
                            <NodeTooltip title="Enter the site name of the tablue server" />
                        </Grid>
                        <Grid item xs={11.5}>
                            <TextField
                                size="small"
                                label="Server URL"
                                variant="outlined"
                                fullWidth
                                value={configuration?.server_url?.value}
                                onChange={(e) => onNodeExecutionConfigrationChange("server_url", e.target.value)}
                            />
                            <Typography variant="h6" style={{ color: "red" }}>
                                {validator.message("Server URL", configuration?.server_url?.value, "required")}
                            </Typography>
                        </Grid>
                        <Grid xs={0.5}>
                            <NodeTooltip title="Enter the server url of the tablue server" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                    <Button onClick={handleFinish} variant="contained" color="primary">
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}