import DatasourceIcon from "assets/images/Orchestration/forward_node_icon.svg";
import SqlIcon from "assets/images/Orchestration/sql_node_icon.svg";
import dbtIcon from "assets/images/Orchestration/dbt_node_icon.svg";
import ReverseIcon from "assets/images/Orchestration/reverse_node_icon.svg";
import decisionNodeIcon from "assets/images/Orchestration/decision_node_icon.svg";
import delayNodeIcon from "assets/images/Orchestration/delay_node_icon.svg";

export const nodeIconMapper = {
    datasourceNode: DatasourceIcon,
    sqlTransformationNode: SqlIcon,
    dbtTransformationNode: dbtIcon,
    reverseConnectorNode: ReverseIcon,
    decisionNode: decisionNodeIcon,
    powerBIRefreshNode: "https://images.datachannel.co/images/connectors/powerbi.png",
    tableauRefreshNode: "https://cdn.worldvectorlogo.com/logos/tableau-software.svg",
    delayNode: delayNodeIcon,
    awsLambdaFunction: "https://cdn.worldvectorlogo.com/logos/aws-lambda-1.svg",
};
