import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import { IconButton, Typography, Grid } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

const useStyles = makeStyles({
    drawerContainer: {
        width: "35vw",
        height: "100%",
        overflow: "scroll",
        backgroundColor: "#efefef",
    },
});

export default function NodeConfigrationDrawer({ isDrawerOpen, setIsDrawerOpen, drawerTitle, children }) {
    const classes = useStyles();

    const anchor = "right";

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    return (
        <>
            <Drawer
                onClick={(e) => e.stopPropagation()}
                anchor={anchor}
                open={isDrawerOpen}
                onClose={closeDrawer}
                // hideBackdrop={true}
                slotProps={{
                    backdrop: {
                        style: { opacity: 0 },
                    },
                }}
                PaperProps={{ style: { position: "absolute" } }}
                ModalProps={{
                    container: document.getElementById("drawer-container"),
                    style: { position: "absolute" },
                }}
                SlideProps={{
                    onEnter: (node) => {
                        node.style.webkitTransform = "scaleX(0)";
                        node.style.transform = "scaleX(0)";
                        node.style.transformOrigin = "top right ";
                    },
                }}
            >
                <div className={classes.drawerContainer}>
                    <Grid style={{ padding: 20 }} container>
                        <Grid
                            item
                            xs={12}
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ borderBottom: "1px solid #dddddd", paddingBottom: 10 }}
                        >
                            <Typography className="size-18 blackText boldText">{drawerTitle}</Typography>
                            <IconButton onClick={closeDrawer} size="large">
                                <CloseOutlined />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </div>
            </Drawer>
        </>
    );
}
