import { Tooltip } from "@mui/material";
import React, { memo } from "react";
import { Handle } from "reactflow";
import flowConfig from "../../common/FlowConfig";
import { useReactFlow, useNodes } from "reactflow";
import { v4 as uuidv4 } from "uuid";

export default function HandleWrapper({ handleId, handleColor, isConnectable, nodeId, data, tooltipTitle, style, position }) {
    const nodes = useNodes();
    const { setNodes, setEdges } = useReactFlow();

    const handleHandleClick = (e, sourceHandle) => {
        e.stopPropagation();
        const currentNode = nodes.filter((node) => node.id === nodeId)[0];
        const newNodeId = uuidv4();
        setNodes((nodes) => [
            ...nodes,
            {
                id: newNodeId,
                type: "nodePicker",
                position: { x: currentNode.position.x + 500, y: currentNode.position.y + 50 },
                data: {
                    borderColor: flowConfig.defaultNodeBorderColor,
                },
            },
        ]);
        if (currentNode.id === "start") {
            setEdges((edges) => [
                ...edges,
                {
                    id: uuidv4(),
                    source: currentNode.id,
                    target: newNodeId,
                    sourceHandle: sourceHandle,
                    animated: true,
                    type: "bezier",
                    edgeStatus: "Success",
                    markerEnd: { type: "arrowclosed" },
                },
            ]);
        } else {
            setEdges((edges) => [
                ...edges,
                {
                    id: uuidv4(),
                    source: currentNode.id,
                    target: newNodeId,
                    sourceHandle: sourceHandle,
                    animated: true,
                    type: "edgeWithInput",
                    edgeStatus: "Success",
                    markerEnd: { type: "arrowclosed" },
                },
            ]);
        }
    };
    return (
        <Tooltip title={tooltipTitle}>
            <Handle
                type="source"
                position={position}
                onClick={(e) => handleHandleClick(e, handleId)}
                id={handleId}
                style={style}
                isConnectable={isConnectable}
            />
        </Tooltip>
    );
}
