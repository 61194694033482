import { Grid, Paper, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Image from "components/common/UIComponents/Image";
import Config from "constants/config";
import { AnimatePresence, motion } from "framer-motion";
import useApi from "hooks/useApi";
import React from "react";
import RibbonMaterialCard from "components/common/Ribbon";
import LabelAndDescription from "components/common/LabelAndDescription";

export default function Configration({ onDatasourceSelect, selectedDatasourceSlug }) {
    const [datasources, loading, makeRequest] = useApi();

    React.useEffect(() => {
        (async () => {
            await makeRequest({
                module: "datasource",
                method: "getConfiguredDatasources",
            });
        })();
    }, []);

    const [filter, setFilter] = React.useState("");
    const [page, setPage] = React.useState(1);
    const source_per_page = 9;

    const getFilteredDatasources = (dataSources) => {
        /**
         * To not show deprecated connectors to new users (with num of reports 0)
         */
        const filteredDatasource = dataSources.filter((ds) => {
            let active_reports = ds.active_reports === null ? 0 : ds.active_reports;
            let paused_reports = ds.paused_reports === null ? 0 : ds.paused_reports;
            if (ds.display_name?.split(" ").splice(-1)[0].toLowerCase() === "dep" && active_reports + paused_reports === 0) {
                return false;
            }
            return true;
        });
        const filtered = filteredDatasource.filter(
            (el) => el.slug.toLowerCase().includes(filter) || el.display_name.toLowerCase().includes(filter)
        );
        const depDatasources = filtered.filter((el) => el?.display_name?.split(" ").splice(-1)[0].toLowerCase() === "dep");
        const nonDepDatasources = filtered.filter((el) => el?.display_name?.split(" ").splice(-1)[0].toLowerCase() !== "dep");
        const datasourcesToReturn = [...nonDepDatasources, ...depDatasources];
        return datasourcesToReturn;
    };

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleSelectDatasource = (datasource) => {
        onDatasourceSelect(datasource);
    };

    const calPageCount = (count, source_per_page) => {
        let remainder = count % source_per_page;
        if (remainder === 0) {
            return parseInt(count / source_per_page);
        } else {
            return parseInt(count / source_per_page) + 1;
        }
    };
    if (loading || datasources === null) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: 250 }}>
                <CircularProgress />
            </Grid>
        );
    }
    return (
        <Grid container>
            <Grid item xs={12} container justifyContent="center" style={{ marginTop: 10 }}>
                <Grid item xs={6}>
                    <TextField
                        size="small"
                        variant="outlined"
                        label="Search"
                        fullWidth
                        onChange={(e) => {
                            setPage(1);
                            setFilter(e.target.value.toLowerCase());
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent={"center"} style={{ marginTop: 10 }}>
                <Grid item xs={12} container justifyContent={"center"}>
                    <Grid xs={12} item>
                        <Typography
                            variant="body1"
                            style={{ fontSize: 12, color: "#626262", textAlign: "center", fontWeight: 500 }}
                            align="center"
                        >
                            Configured Data Sources
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
                <Grid container spacing={4} style={{ margin: 0, width: "100%" }}>
                    <AnimatePresence mode="popLayout" exitBeforeEnter>
                        {getFilteredDatasources(datasources)
                            .filter((datasource) => datasource.data_warehouse_id !== null)
                            .slice(source_per_page * (page - 1), source_per_page * page)
                            .map((datasource) => (
                                <Grid item xs={4}>
                                    <Paper
                                        elevation={0}
                                        onClick={() => handleSelectDatasource(datasource)}
                                        component={motion.div}
                                        initial={{ y: -100, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        whileHover={{ scale: 1.03, border: "1px solid #FD9567", cursor: "pointer" }}
                                        style={{
                                            borderRadius: 10,
                                            display: "flex",
                                            alignItems: "center",
                                            position: "relative",
                                            height: "100%",
                                            border: selectedDatasourceSlug === datasource.slug ? "1px solid #FD9567" : null,
                                            boxShadow:
                                                "0px 100px 80px rgba(12, 44, 108, 0.07), 0px 22.3363px 17.869px rgba(12, 44, 108, 0.0417275), 0px 6.6501px 5.32008px rgba(12, 44, 108, 0.0282725)",
                                        }}
                                    >
                                        {datasource?.display_name?.split(" ").splice(-1)[0].toLowerCase() === "dep" && (
                                            <RibbonMaterialCard />
                                        )}
                                        {datasource?.status === "BETA" && (
                                            <RibbonMaterialCard ribbonText="BETA" ribbonType="SECONDARY" />
                                        )}
                                        <Grid container justifyContent="center" style={{ padding: "20px 10px" }}>
                                            <Grid item>
                                                <Image
                                                    style={{ height: 65, width: 65 }}
                                                    src={`${Config.imageBaseUrl}images/connectors/${datasource.slug}.png`}
                                                    alt={datasource.slug}
                                                    name={datasource.display_name}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    style={{ textAlign: "center", marginTop: 15, fontSize: 14, color: "#152945" }}
                                                >
                                                    {datasource.display_name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))}
                    </AnimatePresence>
                    <Grid container justifyContent="center" style={{ margin: "30px 0px" }}>
                        <Pagination
                            count={calPageCount(
                                getFilteredDatasources(datasources)?.filter((datasource) => datasource.data_warehouse_id !== null)
                                    .length,
                                source_per_page
                            )}
                            color="primary"
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
