import { Typography, Grid } from "@mui/material";
import React from "react";
import "./style.css";
import { findAllPathsToNode, getNodesFromEdges } from "../../common/functions";
import { useReactFlow, useNodes, useEdges } from "reactflow";
import flowConfig from "components/Orchestration/AddOrchestration/common/FlowConfig";

export default function NodeStatusHOC(props) {
    const { setNodes, setEdges } = useReactFlow();
    const nodes = useNodes();
    const edges = useEdges();

    /**
     * Toggles the visibility of the toolbar for the current node.
     */
    const handleToolbarOpen = () => {
        const startNodeId = "start";
        const targetNodeId = props.nodeId;
        const allPathsToNode = findAllPathsToNode(nodes, edges, startNodeId, targetNodeId);
        const allPathEdges = allPathsToNode.flat(1);
        const allEdges = allPathEdges.map((edge) => edge.id);
        const allNodesInPath = getNodesFromEdges(nodes, allPathEdges).map((node) => node.id);
        console.log(props.nodeId);
        if (nodes.filter((node) => node.id === props.nodeId)[0].data.isNodeToolbarOpen) {
            setEdges((edges) =>
                edges.map((edge) => {
                    delete edge.style;
                    return edge;
                })
            );
        } else {
            setEdges((edges) =>
                edges.map((edge) => {
                    if (allEdges.includes(edge.id)) {
                        return { ...edge, style: { stroke: "#FD9567" } };
                    } else {
                        return edge;
                    }
                })
            );
        }
        setNodes(() => {
            const newNodes = JSON.parse(JSON.stringify(nodes));
            newNodes.forEach((node) => {
                if (allNodesInPath.includes(node.id)) {
                    if (nodes.filter((node) => node.id === props.nodeId)[0].data.isNodeToolbarOpen) {
                        if (node?.data?.borderColor) {
                            node.data.borderColor = flowConfig.defaultNodeBorderColor;
                            node.data.status = undefined;
                            node.data.stateMessage = undefined;
                        }
                    } else {
                        if (node?.data?.borderColor) {
                            node.data.borderColor = "#FD9567";
                            node.data.status = "RUNNING";
                            node.data.stateMessage = "In Flow";
                        }
                    }
                }
                if (node.id === props.nodeId) {
                    node.data.isNodeToolbarOpen = !node.data.isNodeToolbarOpen;
                } else {
                    if (node?.data?.isNodeToolbarOpen) node.data.isNodeToolbarOpen = false;
                }
            });
            return newNodes;
        });
    };

    return (
        <>
            {props.status === "SUCCESS" && (
                <div
                    style={{
                        backgroundColor: "#23BE82",
                        display: "inline-block",
                        borderRadius: 8,
                        marginBottom: 5,
                        position: "absolute",
                        top: -40,
                    }}
                >
                    <Typography className="size-12 semiBoldText whiteText" style={{ padding: "5px 20px" }}>
                        {props.nodeData?.stateMessage ? props.nodeData?.stateMessage : "Success"}
                    </Typography>
                </div>
            )}
            {(props.status === "ERROR" || props.status === "FAILED") && (
                <div
                    style={{
                        backgroundColor: "#F77070",
                        display: "inline-block",
                        borderRadius: 8,
                        marginBottom: 5,
                        position: "absolute",
                        top: -40,
                    }}
                >
                    <Typography className="size-12 semiBoldText whiteText" style={{ padding: "5px 20px" }}>
                        {props.nodeData?.errorMessage ? props.nodeData?.errorMessage : "Error"}
                    </Typography>
                </div>
            )}

            {props.status === "RUNNING" && (
                <div
                    style={{
                        backgroundColor: "#FD9E74",
                        display: "inline-block",
                        borderRadius: 8,
                        marginBottom: 5,
                        position: "absolute",
                        top: -40,
                    }}
                >
                    <Grid container alignItems="center" style={{ padding: "5px 20px" }}>
                        <Typography className="size-12 semiBoldText whiteText" style={{ marginRight: 10 }}>
                            {props.nodeData?.stateMessage ? props.nodeData?.stateMessage : "Running"}
                        </Typography>
                        {props.nodeData?.stateMessage === undefined && <span class="loader"></span>}
                    </Grid>
                </div>
            )}

            <div onClick={handleToolbarOpen}>{props.children}</div>
        </>
    );
}
