import { Typography, Grid } from "@mui/material";
import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";

export default memo(({ data, isConnectable }) => {
    return (
        <div style={{ background: "white", border: `2px solid ${data?.borderColor}`, borderRadius: 5 }}>
            <Grid container style={{ width: 100 }}>
                <Grid
                    item
                    xs={5}
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ borderRight: "0.823px solid #E7E7E7" }}
                >
                    <GolfCourseIcon style={{ fontSize: 19, color: "red" }} />
                </Grid>
                <Grid item xs={7} container justifyContent="center">
                    <Typography style={{ padding: "10px 0px" }} className="size-12 blackText">
                        End
                    </Typography>
                </Grid>
            </Grid>
            <Handle type="target" position={Position.Left} id="end-node-target" isConnectable={isConnectable} />
        </div>
    );
});
