import React from "react";
import { Grid, Typography, Button, TextField } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfiguredReports } from "redux/actions/dataSourceActions";
import CircularProgress from "@mui/material/CircularProgress";
import NoData from "components/common/NoData/NoData";
import Pagination from "@mui/material/Pagination";
import { motion } from "framer-motion";
import { useNodes } from "reactflow";

const SelectConfiguredReport = ({
    datasourceSlug,
    onConfiguredReportSelect,
    selectedConfiguredReportId,
    handleBack,
    handleNext,
}) => {
    const dataSource = useSelector((state) => state.dataSource);
    const dispatch = useDispatch();

    const source_per_page = 10;
    const [page, setPage] = React.useState(1);
    const [search, setSearch] = React.useState("");

    const handleChange = (event, value) => {
        setPage(value);
    };

    const getFilteredReports = (reports) => {
        return reports.filter((el) => el.dataset_name.toLowerCase().includes(search.toLowerCase()));
    };

    React.useEffect(() => {
        dispatch(fetchConfiguredReports(datasourceSlug));
    }, [datasourceSlug]);

    if (dataSource.configuredReportsIsLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    }

    if (dataSource.configuredReports.length === 0) {
        return (
            <NoData
                link={`/datasource/${datasourceSlug}/report/new`}
                showButton={true}
                height={"50vh"}
                componentType="configured data pipeline"
            />
        );
    }
    return (
        <>
            <Grid container>
                <Grid style={{ margin: "10px 20px" }} item xs={12} container>
                    <Grid item xs={6} container>
                        <img
                            style={{ height: 25, width: 25 }}
                            src={`https://images-stage.datachannel.co/images/connectors/icons/${datasourceSlug}.png`}
                        />
                        <Typography style={{ marginLeft: 30 }} className="blackText boldText size-16">
                            Data Pipeline Name
                        </Typography>
                    </Grid>
                    <Grid item xs={3} container justifyContent="flex-end">
                        <Typography className="blackText boldText size-16">Last Run Status</Typography>
                    </Grid>
                    <Grid item xs={3} container justifyContent="flex-end">
                        <Typography className="blackText boldText size-16">Rows Moved</Typography>
                    </Grid>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={(e) => setSearch(e.target.value)}
                        label="search"
                    />
                </Grid>
                {getFilteredReports(dataSource.configuredReports)
                    .slice(source_per_page * (page - 1), source_per_page * page)
                    .map((report) => (
                        <Grid
                            key={report.id}
                            component={motion.div}
                            whileHover={{ scale: 1.02, border: "1px solid #FD9567", cursor: "pointer" }}
                            item
                            xs={12}
                            container
                            style={{
                                margin: "5px 20px",
                                borderRadius: 10,
                                boxShadow:
                                    "0px 0.266px 3.72406px 0px rgba(12, 44, 108, 0.08), 0px 0.89345px 12.50833px 0px rgba(12, 44, 108, 0.05), 0px 4px 56px 0px rgba(12, 44, 108, 0.03)",
                                border: report.id === selectedConfiguredReportId ? "1px solid #FD9567" : null,
                            }}
                            onClick={() => onConfiguredReportSelect(report)}
                        >
                            <Grid container style={{ padding: 10 }}>
                                <Grid item xs={6}>
                                    <Typography style={{ wordBreak: "break-all" }}>{report.dataset_name}</Typography>
                                </Grid>
                                <Grid item xs={3} container justifyContent="flex-end">
                                    <Typography>
                                        {report.last_run_status ? report.last_run_status : "No Run Initiated"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} container justifyContent="flex-end">
                                    <Typography>{report.rows_moved}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                <Grid container justifyContent="center" style={{ margin: "30px 0px" }}>
                    <Pagination
                        count={parseInt(getFilteredReports(dataSource.configuredReports).length / source_per_page) + 1}
                        color="primary"
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Button color="primary" variant="outlined" onClick={handleBack}>
                    Back
                </Button>
                <Button
                    disabled={selectedConfiguredReportId === undefined || selectedConfiguredReportId === null}
                    style={{ marginLeft: 10 }}
                    color="primary"
                    variant="contained"
                    onClick={handleNext}
                >
                    Next
                </Button>
            </Grid>
        </>
    );
};

export default SelectConfiguredReport;
