import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import SelectDatasource from "./SelectDatasource";
import SelectConfiguredReport from "./SelectConfiguredReport";
import NodeConfigration from "./NodeConfigration";
import { useReactFlow } from "reactflow";

const steps = [
    { label: "Select Data Source" },
    { label: "Select Data Pipeline" },
    {
        label: "Configure Data Source",
    },
];
export default function Configration({ nodeId, nodeData, handleCloseDrawer }) {
    const { setNodes } = useReactFlow();

    const [activeStep, setActiveStep] = React.useState(0);

    const handleBack = () => {
        setActiveStep((activeStep) => activeStep - 1);
    };

    const handleNext = () => {
        setActiveStep((activeStep) => activeStep + 1);
    };

    const handleSelectDatasource = (datasource) => {
        setNodes((nodes) => {
            nodes.forEach((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        configuration: {
                            ...node.data.configuration,
                            datasource: datasource,
                        },
                    };
                }
            });
            return nodes;
        });
        setActiveStep((activeStep) => activeStep + 1);
    };

    const handleConfiguredReportSelect = (configuredReport) => {
        setNodes((nodes) => {
            nodes.forEach((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        configuration: {
                            ...node.data.configuration,
                            configuredReport: configuredReport,
                        },
                    };
                }
            });
            return nodes;
        });
        setActiveStep((activeStep) => activeStep + 1);
    };

    const handleNodeExecutionConfigrationChange = (paramName, value, operator) => {
        setNodes((nodes) => {
            nodes.forEach((node) => {
                if (node.id === nodeId) {
                    if (node.data?.configuration?.nodeExecutionConfiguration) {
                        node.data = {
                            ...node.data,
                            configuration: {
                                ...node.data.configuration,
                                nodeExecutionConfiguration: {
                                    ...node.data?.configuration?.nodeExecutionConfiguration,
                                    [paramName]: {
                                        value: value,
                                        operator: operator,
                                    },
                                },
                            },
                        };
                    } else {
                        node.data = {
                            ...node.data,
                            configuration: {
                                ...node.data.configuration,
                                nodeExecutionConfiguration: {
                                    [paramName]: {
                                        value: value,
                                        operator: operator,
                                    },
                                },
                            },
                        };
                    }
                }
            });
            console.log(nodes);
            return nodes;
        });
    };

    return (
        <div>
            {nodeData?.configuration?.configuredReport?.id === undefined && (
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label.label}>
                            <StepLabel>
                                <Typography>{label.label}</Typography>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            )}
            {activeStep === 0 && nodeData?.configuration?.configuredReport?.id === undefined && (
                <SelectDatasource
                    selectedDatasourceSlug={nodeData?.configuration?.datasource?.slug}
                    onDatasourceSelect={handleSelectDatasource}
                />
            )}
            {activeStep === 1 &&
                nodeData?.configuration?.configuredReport?.id === undefined &&
                nodeData?.configuration?.datasource?.slug && (
                    <SelectConfiguredReport
                        datasourceSlug={nodeData?.configuration?.datasource?.slug}
                        onConfiguredReportSelect={handleConfiguredReportSelect}
                        selectedConfiguredReportId={nodeData?.configuration?.configuredReport?.id}
                        handleBack={handleBack}
                        handleNext={handleNext}
                    />
                )}
            {activeStep === 2 &&
                nodeData?.configuration?.configuredReport?.id === undefined &&
                nodeData?.configuration?.configuredReport?.id && (
                    <NodeConfigration
                        onNodeExecutionConfigrationChange={handleNodeExecutionConfigrationChange}
                        onFinish={handleCloseDrawer}
                        configuration={nodeData?.configuration?.nodeExecutionConfiguration}
                        handleBack={handleBack}
                    />
                )}
            {nodeData?.configuration?.configuredReport?.id !== undefined && (
                <div style={{ marginTop: 20 }}>
                    <NodeConfigration
                        onNodeExecutionConfigrationChange={handleNodeExecutionConfigrationChange}
                        onFinish={handleCloseDrawer}
                        configuration={nodeData?.configuration?.nodeExecutionConfiguration}
                        handleBack={handleBack}
                    />
                </div>
            )}
        </div>
    );
}
