import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import CredentialNodeConfiguration from "./CredentialNodeConfiguration";
import Modal from "./ModalNodeConfiguration";
import { useReactFlow } from "reactflow";
import SelectWorkbook from "./SelectWorkbook";
import ModalNodeConfiguration from "./ModalNodeConfiguration";

const steps = [{ label: "Tableau Configration" }, { label: "Select Workbook" }];
export default function Configration({ nodeId, nodeData, handleCloseDrawer }) {
    const { setNodes } = useReactFlow();

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNodeExecutionConfigrationChange = (paramName, value, operator = "=") => {
        setNodes((nodes) => {
            nodes.forEach((node) => {
                if (node.id === nodeId) {
                    if (node.data?.configuration?.nodeExecutionConfiguration) {
                        node.data = {
                            ...node.data,
                            configuration: {
                                ...node.data.configuration,
                                nodeExecutionConfiguration: {
                                    ...node.data?.configuration?.nodeExecutionConfiguration,
                                    [paramName]: {
                                        value: value,
                                        operator: operator,
                                    },
                                },
                            },
                        };
                    } else {
                        node.data = {
                            ...node.data,
                            configuration: {
                                ...node.data.configuration,
                                nodeExecutionConfiguration: {
                                    [paramName]: {
                                        value: value,
                                        operator: operator,
                                    },
                                },
                            },
                        };
                    }
                }
            });
            return nodes;
        });
    };

    return (
        <div>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label.label}>
                        <StepLabel>
                            <Typography>{label.label}</Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>

            {
            /*condition to check the type of Node Configuration*/
            activeStep === 0 && (
    nodeData?.configuration?.nodeExecutionConfiguration?.personal_access_token_name !== undefined  
    &&
    nodeData?.configuration?.nodeExecutionConfiguration?.credential === undefined? (
        

        <ModalNodeConfiguration
            onNodeExecutionConfigrationChange={handleNodeExecutionConfigrationChange}
            onFinish={() => setActiveStep((activeStep) => activeStep + 1)}
            configuration={nodeData?.configuration?.nodeExecutionConfiguration}
        />
    ) : (
        <CredentialNodeConfiguration
            onNodeExecutionConfigrationChange={handleNodeExecutionConfigrationChange}
            onFinish={() => setActiveStep((activeStep) => activeStep + 1)}
            configuration={nodeData?.configuration?.nodeExecutionConfiguration}
        />
    )
    )}

            {activeStep === 1 && (
                <SelectWorkbook
                    onNodeExecutionConfigrationChange={handleNodeExecutionConfigrationChange}
                    onFinish={handleCloseDrawer}
                    configuration={nodeData?.configuration?.nodeExecutionConfiguration}
                />
            )}
        </div>
    );
}
