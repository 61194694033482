import ConfiguredReportEndpoints from "../Endpoints/ConfiguredReportEndpoints";
import TransformationEndpoints from "../Endpoints/TransformationEndpoints";
import WarehouseEndpoints from "../Endpoints/WarehouseEndpoints";
import ScheduleEndpoints from "../Endpoints/ScheduleEndpoints";
import DatasourceEndpoints from "../Endpoints/DatasourceEndpoints";
import UserEndpoints from "../Endpoints/UserEndpoints";
import NotificationEndpoints from "../Endpoints/NotificationEndpoints";
import ReportEndpoints from "../Endpoints/ReportEndpoints";
import AuthEndpoints from "../Endpoints/AuthEndpoints";
import ReverseConnectorEndpoints from "../Endpoints/ReverseConnectorEndpoints";
import DataModelEndpoints from "../Endpoints/DataModelEndpoints";
import ConfiguredSyncEndpoints from "../Endpoints/ConfiguredSyncEndpoints";
import AsyncJobsEndpoints from "../Endpoints/AsyncJobsEndpoints";
import SubscriptionEndpoints from "../Endpoints/SubscriptionEndpoints";
import DBTTransformationEndpoints from "../Endpoints/DBTTransformationsEndpoints";
import WebflowAnnouncementsEndpoint from "../Endpoints/WebflowAnnouncementsEndpoint";
import DBTCloudTransformationEndpoints from "../Endpoints/DbtCloudEndpoints";
import AssetsEndpoints from "../Endpoints/AssetsEndpoints";
import WorkspaceEndpoints from "../Endpoints/WorkspaceEndpoints";
import OrchestrationEndpoints from "../Endpoints/OrchestrationEndpoints";
import ExternalEntitiesEndpoints from "../Endpoints/ExternalEntitiesEndpoints";

const endpointMapper = {
    configuredReport: ConfiguredReportEndpoints,
    transformation: TransformationEndpoints,
    warehouse: WarehouseEndpoints,
    schedule: ScheduleEndpoints,
    datasource: DatasourceEndpoints,
    user: UserEndpoints,
    notification: NotificationEndpoints,
    report: ReportEndpoints,
    auth: AuthEndpoints,
    reverseConnectors: ReverseConnectorEndpoints,
    dataModels: DataModelEndpoints,
    configuredSync: ConfiguredSyncEndpoints,
    asyncJobs: AsyncJobsEndpoints,
    subscription: SubscriptionEndpoints,
    dbt: DBTTransformationEndpoints,
    webflowAnnouncements: WebflowAnnouncementsEndpoint,
    dbtCloud: DBTCloudTransformationEndpoints,
    assets: AssetsEndpoints,
    workspace: WorkspaceEndpoints,
    orchestration: OrchestrationEndpoints,
    externalEntities: ExternalEntitiesEndpoints,
};

export default endpointMapper;
