const config = {
    frontendUrl: process.env.REACT_APP_FRONTEND_URL,
    backendUrl: process.env.REACT_APP_DC_BACKEND_URL,
    authUrl: process.env.REACT_APP_AUTH_URL,
    responseType: process.env.REACT_APP_RESPONSE_TYPE,
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUrl: process.env.REACT_APP_REDIRECT_URL,
    scopes: process.env.REACT_APP_SCOPES,
    dataApiUrl: process.env.REACT_APP_DATA_API_URL,
    imageBaseUrl: process.env.REACT_APP_IMAGE_BASE_URL,
    reverseApiUrl: process.env.REACT_APP_REVERSE_API_URL,
    redirectUrlForOauth: process.env.REACT_APP_FRONTEND_URL + "oauth/generic/callback",
    supportedAwsLocations: process.env.REACT_APP_SUPPORTED_AWS_LOCATIONS,
    gst18TaxId: process.env.REACT_APP_GST_18_TAX_ID,
    igst18TaxId: process.env.REACT_APP_IGST_18_TAX_ID,
    firebaseConfig: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG),
    eventCollectorUrl: process.env.REACT_APP_EVENT_COLLECTOR_URL,
    zoho_free_plan_code: "datachannel_free_monthly",
    githubClientId: process.env.REACT_APP_DBT_CLIENT_ID,
    gaTagId: process.env.REACT_APP_GA4_TAG_ID,
};
export default config;
