import { ButtonGroup, Button, Typography, Tooltip, Grid } from "@mui/material";
import React from "react";
import NodeTooltip from "./NodeTooltip";

const notificationOptions = [
    {
        name: "Error",
        slug: "error",
    },
    {
        name: "Completed",
        slug: "completed",
    },
    {
        name: "No Notification",
        slug: "no_notification",
    },
];

export default function NodeNotifications({ onNodeExecutionConfigrationChange, value }) {
    console.log(value);
    if (value === undefined) {
        value = "no_notification";
    }
    return (
        <Grid container item flexDirection="column" style={{ margin: "18px 0px 0px", padding: 15 }}>
            <Typography className="blackText size-14 semiBoldText">Get Notifications On</Typography>
            <Grid container>
                <ButtonGroup color="secondary">
                    {notificationOptions.map((option) => (
                        <Button
                            color="secondary"
                            variant={value === option.slug ? "contained" : "outlined"}
                            onClick={() => onNodeExecutionConfigrationChange("notification_level", option.slug, "=")}
                        >
                            {option.name}
                        </Button>
                    ))}
                </ButtonGroup>
            </Grid>
        </Grid>
    );
}
