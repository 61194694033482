import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import AnnouncementMenu from "components/ProductAnnouncement/AnnouncementMenu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ButtonBase } from "@mui/material";
import CheckPermissions from "services/CheckPermissions";
import Cookies from "js-cookie";
import Grid from "@mui/material/Grid";
import HelpIcon from "@mui/icons-material/HelpOutlineRounded";
import IconButton from "@mui/material/IconButton";
import LimitBarWithOutPointer from "./LimitBarWithPointer";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import NotificationList from "components/common/NotificationList";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import Popover from "@mui/material/Popover";
import { SET_COUNT } from "redux/actionTypes/actionTypes";
import Search from "./Search";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import activeIcon from "assets/images/common/active.png";
import clsx from "clsx";
import config from "constants/config";
import data_channel_logo from "assets/data_channel_logo.png";
import { getAuth } from "firebase/auth";
import infoTriangle from "assets/images/common/Info-triangle-orange.svg";
import logoutIcon from "assets/images/common/logout.svg";
import makeStyles from "@mui/styles/makeStyles";
import manageAccount from "assets/images/common/manageAccount.svg";
import messagequestion from "assets/images/common/messagequestion.svg";
import moment from "moment";
import settingsIcon from "assets/images/common/setting2.svg";
import trialIcon from "assets/images/common/trialIcon.svg";
import updatesIcon from "assets/images/NavBar/updatesIcon.svg";
import WorkspaceSelector from "./Workspace/WorkspaceSelector";
import WorkspaceMenu from "./Workspace/WorkspaceMenu";
import { useNavigate } from "react-router";
import withStyles from "@mui/styles/withStyles";

const drawerWidth = 125;

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "white",
        color: theme.palette.grey[700],
        marginLeft: drawerWidth,
        // marginTop: 15,
        marginRight: 20,
        boxShadow: "0px 100px 80px rgba(69, 37, 18, 0.035), 0px 12.5216px 10.0172px rgba(69, 37, 18, 0.07)",
        borderRadius: "8px",
        width: `calc(100% - ${drawerWidth}px - ${10}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    shrink: {
        width: `calc(100% - ${drawerWidth}px - ${160}px)`,
    },

    toolbar: {
        paddingRight: 24,
        paddingLeft: 24,
    },
    title: {
        flexGrow: 1,
        fontWeight: "bold",
        fontSize: 16,
    },
    caption: {
        color: "#707070",
    },
    userLabel: {
        margin: 10,
        textOverflow: "ellipsis",
        maxWidth: "6vw",
        overflow: "hidden",
    },
    overflowEllipse: {
        textOverflow: "ellipsis",
    },
    logout: {
        margin: 10,
    },
    userAvatar: {
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.main,
        marginLeft: 10,
    },
    popover: {
        // maxHeight: "90vh"
    },
    notificationContainer: {
        padding: 20,
        width: "25vw",
        maxHeight: "80vh",
        overflowX: "hidden",
        overflowY: "scroll",
        [theme.breakpoints.up("xl")]: {
            width: "20vw",
        },
        // minHeight: "60vh"
    },
    notification: {
        marginTop: 25,
    },
    hr: {
        marginTop: 11,
        marginBottom: 12,
        color: "#D9D9D9",
    },
    logo: {
        display: "flex",
        justifyContent: "center",
    },
}));

const StyledPopover = withStyles({
    paper: {
        overflowY: "hidden",
    },
})(Popover);

export default function TopBar(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const notification = useSelector((state) => state.realtimeNotification);
    const user = useSelector((state) => state.users);
    const realtimeNotification = useSelector((state) => state.realtimeNotification);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElPopover, setAnchorElPopover] = useState(null);
    const subscription_status = useSelector((state) => state.users?.user?.subscription_status);
    const plan_name = useSelector((state) => state.users?.user?.plan_name);
    const [announcementMenu, setAnnouncementMenu] = useState(null);
    const auth = getAuth();
    const [hasEmailProvider, setHasEmailProvider] = useState(false);
    const isSideNavOpen = useSelector((state) => state.uiStates.isSideNavOpen);
    const lastClosedAt = useSelector((state) => state.announcement.anncouncementBannerLastClosedAt);
    const latestAnnouncements = useSelector((state) => state.announcement.latestAnnouncements);
    const [workspaceMenu, setWorkspaceMenu] = useState(null);

    useEffect(() => {
        auth.currentUser.providerData?.forEach((e) => {
            if (e.providerId === "password") {
                setHasEmailProvider(true);
            }
        });
    }, []);

    let SubscriptionStatus = null;
    console.log(user.user);
    console.log(user.user.trial_end);
    let updatesCount = 0;
    if (lastClosedAt) {
        latestAnnouncements.forEach((ele) => {
            if (ele.items.length >= 0) {
                ele.items.forEach((e) => {
                    if (new Date(e["created-on"]) > new Date(lastClosedAt)) {
                        updatesCount = updatesCount + 1;
                    }
                });
            }
        });
    } else {
        latestAnnouncements.forEach((ele) => {
            if (ele.items.length >= 0) {
                ele.items.forEach((e) => {
                    updatesCount = updatesCount + 1;
                });
            }
        });
    }

    let oneDay = 1000 * 60 * 60 * 24;
    const diffTimeFixed = ((new Date(user.user.trial_end).getTime() - new Date().getTime()) / oneDay).toFixed(1);
    const diffTime = ((new Date(user.user.trial_end).getTime() - new Date().getTime()) / oneDay).toFixed(0);

    let hourDiff;
    if (diffTimeFixed < 1) {
        hourDiff = ((new Date(user.user.trial_end).getTime() - new Date().getTime()) / (1000 * 60 * 60)).toFixed(0);
    }

    const handleButtonClick = () => {
        navigate("/settings?tab=subscription_management&subTab=0&open_plan=true");
    };
    const handleProfileClick = () => {
        navigate("/settings", {
            state: {
                tab: 0,
                subTab: 1,
            },
        });
    };

    if (plan_name !== "Unlimited") {
        if (subscription_status === "live") {
            SubscriptionStatus = (
                <Typography variant="caption" style={{ textAlign: "center" }}>
                    Status - Active
                </Typography>
            );
        } else if (subscription_status === "trial") {
            SubscriptionStatus = (
                <Grid container direction="column">
                    <Typography variant="caption" style={{ textAlign: "center" }}>
                        {diffTimeFixed < 1
                            ? `Status - Trial ${hourDiff} hours left`
                            : `Status - Trial ${diffTime}
                        ${diffTimeFixed > 1 ? " days left" : " day left"}`}
                    </Typography>
                    <Typography variant="caption" style={{ textAlign: "center" }}></Typography>
                </Grid>
            );
        } else {
            SubscriptionStatus = (
                <Typography variant="caption" style={{ textAlign: "center" }}>
                    Status - Non Renewing
                </Typography>
            );
        }
    }

    useEffect(() => {
        if (Boolean(anchorElPopover)) {
            dispatch({ type: SET_COUNT, payload: 0 });
        }
    }, [anchorElPopover]);

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorElPopover(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElPopover(null);
    };

    const handleLogout = async () => {
        try {
            const auth = getAuth();
            await auth.signOut();
            Cookies.remove("accessToken");
            Cookies.remove("refreshToken");
            Cookies.remove("expiresIn");
            window.localStorage.clear();
            window.location.href = `${config.frontendUrl}login`;
        } catch (e) {
            Cookies.remove("accessToken");
            Cookies.remove("refreshToken");
            Cookies.remove("expiresIn");
            window.localStorage.clear();
            window.location.href = `${config.frontendUrl}login`;
        }
        handleClose();
    };

    if (user.user === null || typeof user.user == "undefined") {
        handleLogout();
    }

    function openHelpPage() {
        window.open("https://docs.datachannel.co ", "_blank");
    }

    /*
        removed the change password button from the top bar
        added settings button to the top bar
    */
    const handleSettings = () => {
        navigate(`/settings`);
    };

    const handleHelpAndSupport = () => {
        document.getElementById("zohohc-asap-web-launcherbox").click();
    };

    const open = Boolean(anchorElPopover);
    const id = open ? "simple-popover" : undefined;
    let full = null;

    if (props.fullWidth) {
        full = {
            width: "calc(100% - 40px)",
        };
    }

    return (
        <>
            <AppBar className={clsx(classes.appBar, { [classes.shrink]: isSideNavOpen })} style={full}>
                <Toolbar className={classes.toolbar}>
                    {props.subscription && (
                        <Box className={classes.logo}>
                            <Link to="/" color="primary" underline="none">
                                <img src={data_channel_logo} alt="DataChannel Logo"></img>
                            </Link>
                            <Grid container item justifyContent="center" alignItems="center" style={{ marginLeft: 10 }}>
                                <Grid item>
                                    <Typography variant="h3">Subscription</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    <Grid container item xs={2}>
                        {" "}
                        <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                            {props.pageTitle}
                        </Typography>
                    </Grid>
                    <Grid xs={5} container justifyContent="center">
                        {props.hideSearch !== true && <Search />}
                    </Grid>
                    {
                        <Popover
                            style={{ marginTop: 40, padding: 0 }}
                            id="simple-menu"
                            anchorEl={announcementMenu}
                            keepMounted
                            open={announcementMenu}
                            onClose={() => setAnnouncementMenu(null)}
                            MenuListProps={{
                                style: {
                                    padding: 0,
                                },
                            }}
                        >
                            {announcementMenu && (
                                <div>
                                    <AnnouncementMenu containerId={"announcement-container"} />
                                </div>
                            )}
                        </Popover>
                    }
                    <WorkspaceMenu open={workspaceMenu} anchor={workspaceMenu} onClose={() => setWorkspaceMenu(null)} />
                    <Grid xs={5.5} container item justifyContent="flex-end">
                        {/* <Button style={{ margin: 0 }}>What's New</Button> */}
                        <Tooltip title="Select Workspace">
                            <WorkspaceSelector onClick={(e) => setWorkspaceMenu(e.currentTarget)} />
                        </Tooltip>{" "}
                        {/* <Button style={{ margin: 0 }}>What's New</Button> */}
                        <Tooltip title="New Updates">
                            <IconButton
                                onClick={(e) => setAnnouncementMenu(e.currentTarget)}
                                style={{ marginRight: 5 }}
                                size="large"
                            >
                                {updatesCount !== 0 ? (
                                    <Badge badgeContent={updatesCount} overlap="circular" color="primary">
                                        <img src={updatesIcon} width={25} />
                                    </Badge>
                                ) : (
                                    <img src={updatesIcon} width={25} />
                                )}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Help">
                            <IconButton onClick={openHelpPage} size="large">
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Notification">
                            <IconButton onClick={handleClick} size="large">
                                <Badge badgeContent={notification.count} overlap="circular" color="primary">
                                    <NotificationsIcon aria-describedby={id} />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <StyledPopover
                            id={id}
                            open={open}
                            anchorEl={anchorElPopover}
                            className={classes.popover}
                            style={{ overflowY: "hidden" }}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <div id="notification-container" className={classes.notificationContainer}>
                                <NotificationList containerId="notification-container" />
                            </div>
                        </StyledPopover>
                        {/* <Box className={classes.userLabel}>
                <Tooltip title={user.user.name}>
                    <Typography variant="caption" className={clsx("size14", classes.overflowEllipse)}>
                        {user.user.name}
                    </Typography>
                </Tooltip>
                <Tooltip title={user.user.account_name}>
                    <Typography className={classes.overflowEllipse} variant="subtitle2">
                        {user.user.account_name}
                    </Typography>
                </Tooltip>
            </Box> */}
                        {auth.currentUser.photoURL != null ? (
                            <Avatar onClick={handleClickMenu} src={auth.currentUser.photoURL} />
                        ) : (
                            <Avatar
                                ari
                                a-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClickMenu}
                                className={classes.userAvatar + " hoverable"}
                            >
                                {user.user.name[0]}
                            </Avatar>
                        )}
                        <Menu
                            style={{ marginTop: 12 }}
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                        >
                            {/* <MenuItem className="size12 nunito blackText" onClick={handleClose}>Manage My Account</MenuItem> */}
                            {/* <MenuItem onClick={handleChangePassword} className="size12 nunito blackText">
                    Change Password
                </MenuItem>
                <MenuItem className="size12 nunito blackText" onClick={handleLogout}>
                    Logout
                </MenuItem> */}

                            <Grid
                                container
                                item
                                xs={12}
                                style={{ width: "320px", margin: 0, marginTop: "-8px" }}
                                alignItems="center"
                                justifyContent="center"
                                // direction="column"
                                spacing={2}
                            >
                                <Grid
                                    item
                                    container
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    xs={12}
                                    style={{
                                        margin: 0,
                                        backgroundImage:
                                            subscription_status === "trial"
                                                ? "linear-gradient(180deg, #EFF4FF 0%, rgba(239, 244, 255, 0) 100%)"
                                                : "",
                                        paddingLeft: 10,
                                    }}
                                >
                                    <Grid item xs={3}>
                                        {auth.currentUser.photoURL != null ? (
                                            <Avatar
                                                className={classes.userAvatar + " hoverable"}
                                                style={{
                                                    width: 50,
                                                    height: 50,
                                                    borderRadius: "50%",
                                                    margin: 0,
                                                }}
                                                src={auth.currentUser.photoURL}
                                            />
                                        ) : (
                                            <Avatar
                                                variant="circular"
                                                className={classes.userAvatar + " hoverable"}
                                                style={{
                                                    width: 50,
                                                    height: 50,
                                                    margin: 0,
                                                    borderRadius: "50%",
                                                }}
                                                onClick={handleProfileClick}
                                            >
                                                {user.user.name[0]}
                                            </Avatar>
                                        )}
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid item container justifyContent="flex=start">
                                            <Typography
                                                variant="caption"
                                                style={{ fontSize: 14, fontWeight: 500, color: "#000000" }}
                                            >
                                                {user.user.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} container justifyContent="flex-start">
                                            <Typography
                                                variant="caption"
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    color: "#627082",
                                                }}
                                            >
                                                {user.user.email}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} container justifyContent="flex-start">
                                            <Typography variant="caption" style={{ textAlign: "left", color: "#627082" }}>
                                                Admin
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {subscription_status === "trial" ? (
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        style={{ backgroundColor: "#FFEDE1", paddingLeft: 30 }}
                                        alignItems="center"
                                    >
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="caption"
                                                style={{ fontWeight: 600, color: "#F57C23", fontSize: 13 }}
                                            >
                                                Free Trial
                                            </Typography>
                                            <br />
                                            <Typography
                                                variant="caption"
                                                style={{ fontWeight: 400, color: "#F57C23", fontSize: 13 }}
                                            >
                                                {diffTimeFixed < 1
                                                    ? `${hourDiff < 0 ? 0 : hourDiff} Hours Left`
                                                    : `${diffTime < 0 ? 0 : diffTime}
                    ${diffTimeFixed > 1 ? " Days Left" : " Day Left"}`}
                                            </Typography>
                                        </Grid>
                                        {user.user.plan_name !== "Unlimited" && (
                                            <CheckPermissions requiredPermission={["user.edit"]}>
                                                <Grid item xs={6}>
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        style={{ borderRadius: 10, margin: 0 }}
                                                        onClick={handleButtonClick}
                                                    >
                                                        {" "}
                                                        Upgrade now
                                                    </Button>
                                                </Grid>
                                            </CheckPermissions>
                                        )}
                                    </Grid>
                                ) : (
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        style={{
                                            backgroundImage: "linear-gradient(180deg, #EFF4FF 0%, rgba(239, 244, 255, 0) 100%)",
                                            paddingLeft: 30,
                                        }}
                                        alignItems="center"
                                    >
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="caption"
                                                style={{ fontWeight: 600, color: "#F57C23", fontSize: 13 }}
                                            >
                                                {user.user.plan_name === "Free Plan"
                                                    ? user.user.plan_name
                                                    : `${user.user.plan_name} Plan`}
                                            </Typography>
                                            <br />
                                            {user.user.plan_name === "Free Plan" && (
                                                <Typography
                                                    variant="caption"
                                                    style={{ fontWeight: 400, color: "#F57C23", fontSize: 13 }}
                                                >
                                                    Next Billing At: {moment(user.user.current_term_end_at).format("DD/MM/YY")}
                                                </Typography>
                                            )}
                                        </Grid>
                                        {user.user.plan_name !== "Unlimited" && (
                                            <CheckPermissions requiredPermission={["user.edit"]}>
                                                <Grid item xs={6}>
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        style={{ borderRadius: 10, margin: 0 }}
                                                        onClick={handleButtonClick}
                                                    >
                                                        Manage Plans
                                                    </Button>
                                                </Grid>
                                            </CheckPermissions>
                                        )}
                                    </Grid>
                                )}
                                <Grid container item xs={12}>
                                    {/* <ButtonBase style={{ width: "100%" }} onClick={handleProfileClick}>
                                        <Grid container item xs={12}>
                                            <Grid container item xs={12} style={{ margin: 5, paddingLeft: 15 }}>
                                                <Grid container item xs={2} justifyContent="flex-start">
                                                    <img src={manageAccount}></img>
                                                </Grid>
                                                <Grid container item xs={10} justifyContent="flex-start">
                                                    <Typography variant="caption" style={{ fontSize: 16 }}>
                                                        Edit Profile
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>

                                    <hr style={{ width: "100%" }} /> */}

                                    {/* <CheckPermissions requiredPermission={["user.edit"]}>

                                <Grid item xs={12} style={{ width: "100%" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: 0, width: "100%" }}
                                        onClick={() => navigate("/settings")}
                                    >
                                        Manage Account
                                    </Button>
                                </Grid>
                            </CheckPermissions> */}
                                    {/* {user.user.plan_name !== "Unlimited" && (
                                <CheckPermissions requiredPermission={["user.edit"]}>

                                    <Grid item container xs={6} style={{ paddingTop: 0 }}>
                                        <Grid item container>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                onClick={handleButtonClick}
                                                style={{ margin: 0, width: "100%" }}
                                            >
                                                Upgrade
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CheckPermissions>
                            )} */}
                                    {/* <Grid
                                item
                                xs={(user.user.plan_name !== "Unlimited" && CheckPermissionFunction(['user.edit'])) ? 6 : 12}
                                container
                                justifyContent="center"
                                style={{ paddingTop: 0 }}
                            >
                                <Grid item container>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        style={{ margin: 0, width: "100%" }}
                                        onClick={handleLogout}
                                    >
                                        Sign out
                                    </Button>
                                </Grid>
                            </Grid> */}
                                    <Grid container item xs={12}>
                                        <ButtonBase style={{ width: "100%" }} onClick={handleHelpAndSupport}>
                                            <Grid container item xs={12} style={{ margin: 5, paddingLeft: 15 }}>
                                                <Grid container item xs={2} justifyContent="flex-start">
                                                    <img src={messagequestion}></img>
                                                </Grid>
                                                <Grid container item xs={10} justifyContent="flex-start">
                                                    <Typography variant="caption" style={{ fontSize: 16 }}>
                                                        Help and support
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ButtonBase>
                                    </Grid>
                                    <hr style={{ width: "100%" }} />
                                    {hasEmailProvider && (
                                        <>
                                            <Grid container item xs={12}>
                                                <ButtonBase style={{ width: "100%" }} onClick={handleSettings}>
                                                    <Grid container item xs={12} style={{ margin: 5, paddingLeft: 15 }}>
                                                        <Grid container item xs={2} justifyContent="flex-start">
                                                            <img src={settingsIcon}></img>
                                                        </Grid>
                                                        <Grid container item xs={10} justifyContent="flex-start">
                                                            <Typography variant="caption" style={{ fontSize: 16 }}>
                                                                Settings
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ButtonBase>
                                            </Grid>
                                            <hr style={{ width: "100%" }} />
                                        </>
                                    )}

                                    <Grid container item xs={12}>
                                        <ButtonBase style={{ width: "100%" }}>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                style={{ margin: 5, paddingLeft: 15 }}
                                                justifyContent="flex-start"
                                                onClick={handleLogout}
                                            >
                                                <Grid container item xs={2} justifyContent="flex-start">
                                                    <img src={logoutIcon}></img>
                                                </Grid>
                                                <Grid container item xs={10} justifyContent="flex-start">
                                                    <Typography variant="caption" style={{ fontSize: 16 }}>
                                                        Log Out
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ButtonBase>
                                    </Grid>
                                    {/* {hasEmailProvider && (
                                <Grid item xs={12} container justifyContent="center" style={{ paddingTop: 0 }}>
                                    <Grid item container>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            style={{ margin: 0, width: "100%" }}
                                            onClick={handleChangePassword}
                                        >
                                            Change Password
                                        </Button>
                                    </Grid>
                                </Grid>
                            )} */}
                                </Grid>
                                {/* <Grid item xs={12} container justifyContent="space-between">
                            <Grid item>
                                <a
                                    style={{ cursor: "pointer" }}
                                    href="https://datachannel.co/privacy-policy/"
                                    target="_blank"
                                >
                                    <Typography
                                        variant="caption"
                                        style={{ textAlign: "center", fontWeight: 600, color: "black" }}
                                    >
                                        <Typography
                                            variant="caption"
                                            style={{ textAlign: "center", fontWeight: 600, color: "black" }}
                                        >
                                            PRIVACY POLICY
                                        </Typography>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a
                                        style={{ cursor: "pointer" }}
                                        href="https://datachannel.co/terms-of-services/"
                                        target="_blank"
                                    >
                                        <Typography
                                            variant="caption"
                                            style={{ textAlign: "center", fontWeight: 600, color: "black" }}
                                        >
                                            TERMS & CONDITIONS
                                        </Typography>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid> */}
                            </Grid>
                        </Menu>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
}
