import React from "react";
import useApi from "hooks/useApi";
import { Autocomplete, CircularProgress, TextField, Typography, Grid, Button } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import NodeNotifications from "../common/NodeNotifications";

const filterOptions = (options, state) => {
    const newOptions = [...options].filter((op) => op.name.toLowerCase().includes(state.inputValue.toLowerCase()));
    return newOptions;
};

export default function SelectWorkbook({ onNodeExecutionConfigrationChange, onFinish, configuration }) {
    const [, dynamicDataLoading, getDynamicParamData] = useApi();
    const [, credDataLoading, getCredData] = useApi();

    const [workbooks, setWorkbooks] = React.useState([]);

    const getAllWorkbooks = async () => {
        let userData = {}
                    /*condition to check the type of Node Configuration done */

        if(configuration?.personal_access_token_name!==undefined && 
            configuration?.credential === undefined){
            userData = {
                personal_access_token_name: configuration?.personal_access_token_name?.value,
                personal_access_token_secret: configuration?.personal_access_token_secret?.value,
                site_name: configuration?.site_name?.value,
                server_url: configuration?.server_url?.value,
            };

        }
        else{
            let credData = await getCredData({
                module: "orchestration",
                method: "getOrchestrationNodeCredentialData",
                apiData: {
                    node_slug: "tableauRefreshNode",
                    data:{"credential":configuration?.credential?.value?.id}
                },
                returnResult:true,
            })
            
            userData = credData;

        }
        
        let data = await getDynamicParamData({
            module: "orchestration",
            method: "getOrcehstrationDynamicParamData",
            apiData: {
                node_slug: "tableauRefreshNode",
                property_name: "workbook_id",
                data: userData,
            },
            returnResult: true,
        });
        setWorkbooks(data);
    };
    React.useEffect(() => {
        getAllWorkbooks();
    }, []);
    return (
        <>
            {dynamicDataLoading ? (
                <Grid container justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    <Grid style={{ margin: "18px 0px 0px", padding: "15px 15px 0px" }}>
                        <Typography className="blackText size-14 semiBoldText">Select Workbook</Typography>
                        <Autocomplete
                            options={workbooks}
                            filterOptions={filterOptions}
                            isOptionEqualToValue={(option, value) => value.id === option.id}
                            getOptionLabel={(option) => option.name}
                            getOptionKey={(option) => option.id}
                            onChange={(e, newValue, reason) => onNodeExecutionConfigrationChange("workbook_id", newValue, "=")}
                            value={configuration?.workbook_id?.value}
                            renderInput={(params) => (
                                <TextField {...params} size="small" label="Select Workbook" variant="outlined" />
                            )}
                        />
                    </Grid>
                    <NodeNotifications
                        onNodeExecutionConfigrationChange={onNodeExecutionConfigrationChange}
                        value={configuration?.notification_level?.value}
                    />
                    <Grid container justifyContent="center" style={{ margin: "24px 0px" }}>
                        <Grid item>
                            <Button onClick={onFinish} variant="contained" color="primary">
                                Finish
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
