import { Typography, Tooltip } from "@mui/material";
import React from "react";

/**
 *
 * @component
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.description
 * @param {boolean} props.required
 * @returns
 * @example
 * const label = "Label";
 * const description = "Description";
 * const required = true;
 *
 * return <LabelAndDescription
 *       label={label}
 *       description={description
 *       required={required}
 * />;
 */
export default function LabelAndDescription({ label, description, required }) {
    return (
        // [x] added marked required
        <>
            <Typography className="boldText textTruncateTwoLine" variant="h6">
                {label}
                {required ? <span style={{ color: "orange" }}>*</span> : null}
                {description && <span style={{ fontSize: 10, color: "rgb(183 182 182)", marginTop: 5 }}>- {description}</span>}
            </Typography>
        </>
    );
}
