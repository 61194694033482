import React, { useEffect, useMemo, useState } from "react";
import { HotKeys } from "react-hotkeys";
import { useNavigate } from "react-router-dom";
import ShortcutPopUp from "./ShortcutPopUp";
import { Categories } from "./config";

const KeyboardShortcut = () => {
    const [showPopUp, setShowPopUp] = useState(false);
    const navigate = useNavigate();

    const shortcuts = useMemo(() => {
        return Categories.reduce((allShortcuts, category) => {
            const categoryShortcuts = category.events.map((event) => event);
            return allShortcuts.concat(categoryShortcuts);
        }, []);
    }, []);

    const { keyMap, handlers } = useMemo(() => {
        const handlers = {};
        const keyMap = {};

        shortcuts.forEach((shortcut) => {
            const actionKey = shortcut.control;
            keyMap[actionKey] = shortcut.control;
            handlers[actionKey] = (event) => {
                event.preventDefault();
                navigate(shortcut.route);
            };
        });
        return { keyMap, handlers };
    }, [shortcuts, navigate]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            const modifiers = [];

            if (event.ctrlKey || event.metaKey) modifiers.push("ctrl");
            // if (event.metaKey) modifiers.push('command');
            if (event.shiftKey) modifiers.push("shift");
            if (event.altKey) modifiers.push("alt");

            const key = event.key?.toLowerCase();
            const combinationKey = [...modifiers, key].join("+");
            console.log("Combination", combinationKey);

            if (event.ctrlKey && event.key === "/") {
                event.preventDefault();
                setShowPopUp(true);
            } else {
                if (keyMap[combinationKey]) {
                    event.preventDefault();
                    // setShowPopUp(false);
                    handlers[combinationKey](event);
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [shortcuts, handlers]);

    const togglePopUp = () => {
        setShowPopUp((prevState) => !prevState);
    };

    return (
        <HotKeys keyMap={keyMap} handlers={handlers}>
            {showPopUp ? <ShortcutPopUp togglePopUp={togglePopUp} showPopUp={showPopUp} /> : null}
        </HotKeys>
    );
};

export default KeyboardShortcut;
