import { IconButton, Paper, Tooltip, Fade, Grid, Modal, Typography, Button } from "@mui/material";
import React, { memo } from "react";
import { NodeToolbar, useReactFlow, useNodes, useEdges } from "reactflow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { findAllPathsToNode, getNodesFromEdges } from "../../common/functions";
import flowConfig from "components/Orchestration/AddOrchestration/common/FlowConfig";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    cross: {
        // margin: "15px 20px",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 4),
        borderRadius: "16px",
        border: "1px solid #E40D0D",
        position: "relative",
    },
    icon: {
        paddingRight: "36px",
    },
}));
/**
 * React component representing the toolbar for a DataChannel node.
 *
 * @param {Object} props - Props object containing node data and related functions.
 * @returns {JSX.Element} A React component representing the toolbar for a DataChannel node.
 */
export default function DCNodeToolbar({ data, nodeId, onEdit }) {
    const classes = useStyles();
    const nodes = useNodes();
    const edges = useEdges();
    const { setNodes, setEdges } = useReactFlow();

    const [warningModelOpen, setWarningModalOpen] = React.useState(false);
    const [swapWarningModelOpen, setSwapWarningModalOpen] = React.useState(false);

    /**
     * Handles the deletion of the node and its associated edges from the flow.
     */
    const handleDelete = () => {
        setNodes((nodes) => nodes.filter((node) => node.id !== nodeId));
        setEdges((edges) => edges.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
    };

    /**
     * Toggles the visibility of the toolbar for the current node.
     */
    const handleToolbarOpen = () => {
        const startNodeId = "start";
        const targetNodeId = nodeId;
        const allPathsToNode = findAllPathsToNode(nodes, edges, startNodeId, targetNodeId);
        const allPathEdges = allPathsToNode.flat(1);
        const allEdges = allPathEdges.map((edge) => edge.id);
        const allNodesInPath = getNodesFromEdges(nodes, allPathEdges).map((node) => node.id);

        if (nodes.filter((node) => node.id === nodeId)[0].data.isNodeToolbarOpen) {
            setEdges((edges) =>
                edges.map((edge) => {
                    delete edge.style;
                    return edge;
                })
            );
        } else {
            setEdges((edges) =>
                edges.map((edge) => {
                    if (allEdges.includes(edge.id)) {
                        return { ...edge, style: { stroke: "#FD9567" } };
                    } else {
                        return edge;
                    }
                })
            );
        }
        setNodes(() => {
            const newNodes = JSON.parse(JSON.stringify(nodes));
            newNodes.forEach((node) => {
                if (allNodesInPath.includes(node.id)) {
                    if (nodes.filter((node) => node.id === nodeId)[0].data.isNodeToolbarOpen) {
                        if (node?.data?.borderColor) {
                            node.data.borderColor = flowConfig.defaultNodeBorderColor;
                            node.data.status = undefined;
                            node.data.stateMessage = undefined;
                        }
                    } else {
                        if (node?.data?.borderColor) {
                            node.data.borderColor = "#FD9567";
                            node.data.status = "RUNNING";
                            node.data.stateMessage = "In Flow";
                        }
                    }
                }
                if (node.id === nodeId) {
                    node.data.isNodeToolbarOpen = !node.data.isNodeToolbarOpen;
                } else {
                    if (node?.data?.isNodeToolbarOpen) node.data.isNodeToolbarOpen = false;
                }
            });
            return newNodes;
        });
    };

    /**
     * Handles the swapping of the current node with a different node type.
     */
    const handleSwap = () => {
        setNodes((nodes) => {
            nodes.forEach((node) => {
                if (node.id === nodeId) {
                    node.type = "nodePicker";
                }
            });
            return nodes;
        });
    };

    const handleClose = () => {
        setWarningModalOpen(false);
    };
    const handleSwapModalClose = () => {
        setSwapWarningModalOpen(false);
    };
    return (
        <>
            <NodeToolbar onClick={(e) => e.stopPropagation()} isVisible={data.isNodeToolbarOpen}>
                <Modal className={classes.modal} open={warningModelOpen} onClose={handleClose}>
                    <Fade in={warningModelOpen}>
                        <div className={classes.paper}>
                            <Grid className={classes.cross}></Grid>
                            <Grid>
                                <Typography variant="h3">Are you sure you want to delete this node?</Typography>
                                <Typography variant="h3">
                                    This action cannot be undone and all selected configration will be lost.
                                </Typography>
                                <Grid container justifyContent="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            handleDelete();
                                            handleClose();
                                        }}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                            <CloseIcon
                                className="hoverable"
                                style={{ position: "absolute", margin: 12, top: 0, right: 0 }}
                                onClick={() => handleClose()}
                                fontSize="small"
                            />
                        </div>
                    </Fade>
                </Modal>

                <Modal className={classes.modal} open={swapWarningModelOpen} onClose={handleSwapModalClose}>
                    <Fade in={swapWarningModelOpen}>
                        <div className={classes.paper}>
                            <Grid className={classes.cross}></Grid>
                            <Grid>
                                <Typography variant="h3">Are you sure you want to swap this node?</Typography>
                                <Typography variant="h3">
                                    This action cannot be undone and all selected configration will be lost.
                                </Typography>
                                <Grid container justifyContent="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            handleSwap();
                                            handleSwapModalClose();
                                        }}
                                    >
                                        Swap
                                    </Button>
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={handleSwapModalClose}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                            <CloseIcon
                                className="hoverable"
                                style={{ position: "absolute", margin: 12, top: 0, right: 0 }}
                                onClick={() => handleSwapModalClose()}
                                fontSize="small"
                            />
                        </div>
                    </Fade>
                </Modal>

                <Paper elevation={0} style={{ border: "1px solid #E7E7E7" }}>
                    <Tooltip title="Edit Node">
                        <IconButton onClick={onEdit} size="large">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Node">
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setWarningModalOpen(true);
                            }}
                            size="large"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Swap Node">
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setSwapWarningModalOpen(true);
                            }}
                            size="large"
                        >
                            <SwapCallsIcon />
                        </IconButton>
                    </Tooltip>
                </Paper>
            </NodeToolbar>
            {data.isNodeToolbarOpen ? (
                <CloseIcon className="hoverable" style={{ fontSize: 19, marginRight: 10 }} onClick={handleToolbarOpen} />
            ) : (
                <MoreVertIcon className="hoverable" style={{ fontSize: 19, marginRight: 10 }} onClick={handleToolbarOpen} />
            )}
        </>
    );
}
