const detectLoops = (nodes, edges) => {
    const graph = new Map();
    const visited = new Set();
    const stack = new Set();
    const result = [];

    // Build the graph
    edges.forEach((edge) => {
        if (!graph.has(edge.source)) {
            graph.set(edge.source, []);
        }
        graph.get(edge.source).push(edge.target);
    });

    function dfs(node) {
        visited.add(node);
        stack.add(node);

        if (graph.has(node)) {
            for (const neighbor of graph.get(node)) {
                if (!visited.has(neighbor)) {
                    dfs(neighbor);
                } else if (stack.has(neighbor)) {
                    const cycle = Array.from(stack).slice(Array.from(stack).indexOf(neighbor));
                    result.push(...cycle);
                }
            }
        }

        stack.delete(node);
    }

    nodes.forEach((node) => {
        const nodeId = node.id;
        if (!visited.has(nodeId)) {
            dfs(nodeId);
        }
    });

    return result.map((id) => nodes.find((node) => node.id === id));
};

export const detectLoopsInGraph = (nodes, edges) => {
    let loopEdges = [];
    let loopNodes = detectLoops(nodes, edges).map((node) => node.id);
    loopNodes.push(loopNodes[0]);

    loopNodes.forEach((node_id, index) => {
        if (index < loopNodes.length - 1) {
            let edge = edges.filter((edge) => edge.source === node_id && edge.target === loopNodes[index + 1])[0];
            if (edge) {
                loopEdges.push(edge.id);
            }
        }
    });

    return { loopNodes, loopEdges };
};

const graphBfs = (currentNodeId, nodes, edges, visitedNodes) => {
    visitedNodes.push(currentNodeId);
    let nextNodes = [];
    edges.forEach((edge) => {
        if (edge.source === currentNodeId && nodes.filter((node) => node.id === edge.target).length > 0) {
            nextNodes.push(edge.target);
        }
    });
    if (nextNodes.length > 0) {
        nextNodes.forEach((node_id) => {
            graphBfs(node_id, nodes, edges, visitedNodes);
        });
    }
};

export const detectIslandsInGraph = (nodes, edges) => {
    let islandNodes = [];
    let islandEdges = [];

    let visitedNodes = [];

    graphBfs("start", nodes, edges, visitedNodes);

    islandNodes = nodes.filter((node) => !visitedNodes.includes(node.id)).map((el) => el.id);
    islandEdges = edges
        .filter((edge) => islandNodes.includes(edge.source) || islandNodes.includes(edge.target))
        .map((edge) => edge.id);
    return { islandNodes, islandEdges };
};

export function findAllPathsToNode(nodes, edges, startNodeId, targetNodeId) {
    const allPaths = [];

    function backtrack(currentNodeId, currentPath) {
        if (currentNodeId === targetNodeId) {
            // Found a path to the target node, add it to the result
            allPaths.push([...currentPath]);
            return;
        }

        const outgoingEdges = edges.filter((edge) => edge.source === currentNodeId);

        for (const edge of outgoingEdges) {
            currentPath.push(edge);
            backtrack(edge.target, currentPath);
            currentPath.pop();
        }
    }

    backtrack(startNodeId, []);

    return allPaths;
}

export function getNodesFromEdges(nodes, edges) {
    const nodeSet = new Set();

    edges.forEach((edge) => {
        nodeSet.add(edge.source);
        nodeSet.add(edge.target);
    });

    const nodesFromEdges = nodes.filter((node) => nodeSet.has(node.id));

    return nodesFromEdges;
}
