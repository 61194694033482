import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import SelectConfiguredReport from "./SelectTransformation";
import NodeConfigration from "./NodeConfigration";
import { useReactFlow } from "reactflow";

const steps = [{ label: "Select DBT Transformation" }, { label: "Configure DBT Transformation" }];
export default function Configration({ nodeId, nodeData, handleCloseDrawer }) {
    const { setNodes } = useReactFlow();

    const [activeStep, setActiveStep] = React.useState(0);

    const handleBack = () => {
        setActiveStep((activeStep) => activeStep - 1);
    };

    const handleNext = () => {
        setActiveStep((activeStep) => activeStep + 1);
    };

    const handleTransformationSelect = (transformation) => {
        setNodes((nodes) => {
            nodes.forEach((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        configuration: {
                            ...node.data.configuration,
                            dbtTransformation: transformation,
                        },
                    };
                }
            });
            return nodes;
        });
        setActiveStep((activeStep) => activeStep + 1);
    };

    const handleNodeExecutionConfigrationChange = (paramName, value, operator) => {
        setNodes((nodes) => {
            nodes.forEach((node) => {
                if (node.id === nodeId) {
                    if (node.data?.configuration?.nodeExecutionConfiguration) {
                        node.data = {
                            ...node.data,
                            configuration: {
                                ...node.data.configuration,
                                nodeExecutionConfiguration: {
                                    ...node.data?.configuration?.nodeExecutionConfiguration,
                                    [paramName]: {
                                        value: value,
                                        operator: operator,
                                    },
                                },
                            },
                        };
                    } else {
                        node.data = {
                            ...node.data,
                            configuration: {
                                ...node.data.configuration,
                                nodeExecutionConfiguration: {
                                    [paramName]: {
                                        value: value,
                                        operator: operator,
                                    },
                                },
                            },
                        };
                    }
                }
            });
            console.log(nodes);
            return nodes;
        });
    };

    return (
        <div>
            {nodeData?.configuration?.dbtTransformation?.id === undefined && (
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label.label}>
                            <StepLabel>
                                <Typography>{label.label}</Typography>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            )}
            {activeStep === 0 && nodeData?.configuration?.dbtTransformation?.id === undefined && (
                <SelectConfiguredReport
                    selectedTransformationId={nodeData?.configuration?.dbtTransformation?.id}
                    onTransformationSelect={handleTransformationSelect}
                    handleNext={handleNext}
                />
            )}
            {nodeData?.configuration?.dbtTransformation?.id && (
                <div style={{ marginTop: 20 }}>
                    <NodeConfigration
                        onNodeExecutionConfigrationChange={handleNodeExecutionConfigrationChange}
                        onFinish={handleCloseDrawer}
                        configuration={nodeData?.configuration?.nodeExecutionConfiguration}
                        handleBack={handleBack}
                    />
                </div>
            )}
        </div>
    );
}
