import {
    SET_DBT_TRANSFORMATIONS,
    SET_DBT_TRANSFORMATIONS_LOADING,
    SET_DBT_CREDENTIALS,
    SET_DBT_CREDENTIALS_LOADING,
} from "../actionTypes/actionTypes";
// import { getTransformations } from "../../services/Api/api"
import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";

const fetchDbtTransformations = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_DBT_TRANSFORMATIONS_LOADING,
        SET_DBT_TRANSFORMATIONS,
        {
            module: "dbt",
            method: "getAll",
        },
        HttpErrorHandler
    );
};

const fetchDbtCreds = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_DBT_CREDENTIALS_LOADING,
        SET_DBT_CREDENTIALS,
        {
            module: "dbt",
            method: "getAllCreds",
        },
        HttpErrorHandler
    );
};

export { fetchDbtTransformations, fetchDbtCreds };
