import { Grid, TextField, Typography, Collapse, Button, ButtonGroup, Tooltip } from "@mui/material";
import { Autocomplete } from "@mui/material";
import NodeNotifications from "../common/NodeNotifications";
import React from "react";
import NodeTooltip from "../common/NodeTooltip";

export default function NodeConfigration({ onNodeExecutionConfigrationChange, onFinish, configuration }) {
    const [freshnessRequiredError, setFreshnessRequiredError] = React.useState(false);
    const handleFinish = () => {
        if (configuration?.run_pipeline?.value === false && configuration?.freshnessDuration?.value === undefined) {
            setFreshnessRequiredError(true);
        } else {
            onFinish();
        }
    };
    React.useEffect(() => {
        if (configuration?.run_pipeline?.value === undefined) {
            onNodeExecutionConfigrationChange("run_pipeline", true, "=");
        }
        if (configuration?.retryCount?.value === undefined) {
            onNodeExecutionConfigrationChange("retryCount", "1", "=");
        }
    }, []);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container style={{ border: "1px solid #EAEAEA", borderRadius: 15 }}>
                    <Grid style={{ padding: 10 }} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="blackText size-14 semiBoldText">Number of tries </Typography>
                        </Grid>
                        <Grid item xs={11.5}>
                            <Autocomplete
                                options={["1", "2", "3"]}
                                defaultValue="1"
                                isOptionEqualToValue={(option, value) => value === option}
                                onChange={(e, newValue, reason) => onNodeExecutionConfigrationChange("retryCount", newValue, "=")}
                                value={configuration?.retryCount?.value}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField {...params} size="small" label="Number of tries" variant="outlined" />
                                )}
                            />
                        </Grid>
                        <Grid xs={0.5}>
                            <NodeTooltip title="Number of times the node will be retried in case of failure." />
                        </Grid>
                        <Grid item xs={11.5}>
                            <Autocomplete
                                options={["10", "20", "30"]}
                                isOptionEqualToValue={(option, value) => value === option}
                                onChange={(e, newValue, reason) =>
                                    onNodeExecutionConfigrationChange("retryDuration", newValue, "=")
                                }
                                value={configuration?.retryDuration?.value}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField {...params} size="small" label="Retry Interval (minutes)" variant="outlined" />
                                )}
                            />
                        </Grid>
                        <Grid xs={0.5}>
                            <NodeTooltip title="Time interval between each retry." />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container style={{ border: "1px solid #EAEAEA", borderRadius: 10, margin: "15px 0px" }}>
                    <Grid style={{ padding: 10 }} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="blackText size-14 semiBoldText">Run Pipeline</Typography>
                            <Grid container alignItems="center">
                                <ButtonGroup>
                                    <Tooltip title="Select this if you want to check if the data is fresh for pipeline.">
                                        <Button
                                            color="secondary"
                                            style={{ marginTop: 0, marginBottom: 0 }}
                                            onClick={() => onNodeExecutionConfigrationChange("run_pipeline", false, "=")}
                                            variant={configuration?.run_pipeline?.value === false ? "contained" : "outlined"}
                                        >
                                            Check Freshness
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Select this if you want to run the pipeline everytime.">
                                        <Button
                                            color="secondary"
                                            style={{ marginTop: 0, marginBottom: 0 }}
                                            onClick={() => onNodeExecutionConfigrationChange("run_pipeline", true, "=")}
                                            variant={configuration?.run_pipeline?.value === true ? "contained" : "outlined"}
                                        >
                                            Run Everytime
                                        </Button>
                                    </Tooltip>
                                </ButtonGroup>
                            </Grid>
                        </Grid>

                        {!configuration?.run_pipeline?.value && (
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={["1", "2", "4", "6", "8", "12", "16", "24"]}
                                    isOptionEqualToValue={(option, value) => value === option}
                                    getOptionLabel={(option) => option}
                                    onChange={(e, newValue, reason) =>
                                        onNodeExecutionConfigrationChange("freshnessDuration", newValue, "=")
                                    }
                                    value={configuration?.freshnessDuration?.value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            label="Freshness Duration (Hours)"
                                            variant="outlined"
                                            error={freshnessRequiredError}
                                            helperText={freshnessRequiredError && "Freshness duration is a required parameter."}
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid xs={11.5}>
                            <NodeNotifications
                                onNodeExecutionConfigrationChange={onNodeExecutionConfigrationChange}
                                value={configuration?.notification_level?.value}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Button onClick={handleFinish} variant="contained" color="primary" style={{ margin: 0 }}>
                        Finish
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}