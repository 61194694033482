import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import Button from '@mui/material/Button';
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import UserForm from "./common/userForms/UserForm";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#FAFAFA",
		padding: 10
	},
	container: {
		marginTop: 10,
		display: "flex",
		paddingTop: 40,
		paddingBottom: 40,
		paddingLeft: 50,
		paddingRight: 50
	},
	image: {
		width: 200,
		height: 200,
		backgroundColor: "#FFF7F4",
		boder: "1px solid pink",
		marginRight: 20,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: theme.palette.primary.main
	},
	form: {
		flex: 1.2
	},
	formhead: {
		fontSize: 13,
		color: "#2C2C2C",
		fontWeight: 600,
		marginBottom: 15
	},
	tooltip: {
		marginLeft: 10,
		color: "#A5AEB7"
	},
	warningicon: {
		marginRight: 10
	},
	warningtext: {
		fontSize: 14
	},
	buttons: {
		width: "80%"
	},
	info: {
		flex: 1
	},
	infohead: {
		fontSize: 13,
		color: "#2C2C2C",
		fontWeight: 600,
		marginRight: 10
	},
	divider: {
		width: 40,
		backgroundColor: theme.palette.primary.main,
		height: 2
	},
	infodetail: {
		fontSize: 12,
		fontColor: "#909090",
		display: "flex",
		marginTop: 15
	},
	infostep: {
		textTransform: "capitalize",
		height: 15,
		width: 15,
		marginRight: 10,
		fontSize: 8,
		backgroundColor: "black",
		color: "#FFFFFF"
	}
}));

export default function AddUser(props) {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<Paper className={classes.container}>
				<Box className={classes.image}>
					<CameraAltOutlinedIcon fontSize="large" />
				</Box>
				<Box className={classes.form}>
					<Grid container>
						<Grid item xs={10}>
							<UserForm click={props.onClick} />
						</Grid>
					</Grid>
				</Box>

				<Box className={classes.info}>
					<Box display="flex">
						<Typography className={classes.infohead}>How to add a User?</Typography>
						{/* <Link color="primary" underline="none"> */}
						<LinkOutlinedIcon fontSize="small" />
						{/* </Link> */}
					</Box>
					<Divider classes={{ root: classes.divider }} />
					<Box width={500}>
						<ol className="helpList">
							<li>
								<Typography variant="h6">Enter the first and last name of the user.</Typography>
							</li>
							<li>
								<Typography variant="h6">
									Assign a user role(s) from the drop-down as Guest, Admin, or User.
								</Typography>
							</li>
							<li>
								<Typography variant="h6">
									{" "}
									Enter an email address and phone number (Note: Email address can not be updated).
								</Typography>
							</li>
							<li>
								<Typography variant="h6">Click Save.</Typography>
							</li>
						</ol>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
}
