import { Grid, TextField, Typography, Switch, Button } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React from "react";
import NodeTooltip from "../common/NodeTooltip";

export default function NodeConfigration({ onNodeExecutionConfigrationChange, onFinish, configuration }) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container style={{ border: "1px solid #EAEAEA", borderRadius: 15 }}>
                    <Grid style={{ padding: 10 }} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="blackText size-14 semiBoldText">Delay Settings</Typography>
                        </Grid>
                        <Grid item xs={11.5}>
                            <TextField
                                size="small"
                                label="Delay (in seconds)"
                                variant="outlined"
                                fullWidth
                                value={configuration?.delay?.value}
                                onChange={(e) => onNodeExecutionConfigrationChange("delay", e.target.value)}
                            />
                        </Grid>
                        <Grid xs={0.5}>
                            <NodeTooltip title="Number Of seconds " />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                    <Button onClick={onFinish} variant="contained" color="primary">
                        Finish
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
