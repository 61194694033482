import { Grid, TextField, Typography, Switch, Button } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import Credentials from "components/common/Credentials";
import NodeTooltip from "../common/NodeTooltip";
import NodeNotifications from "../common/NodeNotifications";
/**
 * custom hook for forceupdate. used with simple-react-validator
 */
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
}

export default function CredentialNodeConfiguration({ onNodeExecutionConfigrationChange, onFinish, configuration }) {
    const handleFinish = () => {
        if (validator.allValid()) {
            onFinish();
        } else {
            validator.showMessages();
            forceUpdate();
        }
    }
    const forceUpdate = useForceUpdate();
    const validator = useRef(new SimpleReactValidator()).current;

    const handleSetSelectedCredential = (cred) => {
        onNodeExecutionConfigrationChange("credential", cred, "=");
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <Credentials
                            datasourceSlug="tableau"
                            target="ADD"
                            selectedCredential={configuration?.credential?.value}
                            setSelectedCredential={handleSetSelectedCredential}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                    <Button onClick={handleFinish} variant="contained" color="primary">
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
    
}