import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import NodeConfigration from "./NodeConfigration";
import SelectDataset from "./SelectLambdaFunction";
import { useReactFlow } from "reactflow";

const steps = [{ label: "Aws Lambda Credential Configuration" }, { label: "Select Lambda Function" }];
export default function Configration({ nodeId, nodeData, handleCloseDrawer }) {
    const { setNodes } = useReactFlow();

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNodeExecutionConfigrationChange = (paramName, value, operator = "=") => {
        setNodes((nodes) => {
            nodes.forEach((node) => {
                if (node.id === nodeId) {
                    if (node.data?.configuration?.nodeExecutionConfiguration) {
                        node.data = {
                            ...node.data,
                            configuration: {
                                ...node.data.configuration,
                                nodeExecutionConfiguration: {
                                    ...node.data?.configuration?.nodeExecutionConfiguration,
                                    [paramName]: {
                                        value: value,
                                        operator: operator,
                                    },
                                },
                            },
                        };
                    } else {
                        node.data = {
                            ...node.data,
                            configuration: {
                                ...node.data.configuration,
                                nodeExecutionConfiguration: {
                                    [paramName]: {
                                        value: value,
                                        operator: operator,
                                    },
                                },
                            },
                        };
                    }
                }
            });
            return nodes;
        });
    };

    return (
        <div>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label.label}>
                        <StepLabel>
                            <Typography>{label.label}</Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>

            {activeStep === 0 && (
                <NodeConfigration
                    onNodeExecutionConfigrationChange={handleNodeExecutionConfigrationChange}
                    handleNext={() => setActiveStep((activeStep) => activeStep + 1)}
                    configuration={nodeData?.configuration?.nodeExecutionConfiguration}
                />
            )}
            {activeStep === 1 && (
                <SelectDataset
                    onNodeExecutionConfigrationChange={handleNodeExecutionConfigrationChange}
                    handleFinish={handleCloseDrawer}
                    configuration={nodeData?.configuration?.nodeExecutionConfiguration}
                    onFinish={handleCloseDrawer}
                />
            )}
        </div>
    );
}
