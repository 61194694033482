import React, { useRef } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import { Field, Form, Formik, useField, useFormikContext } from "formik";
import { CircularProgress, FormGroup } from "@mui/material";
import { object } from "yup";
import { getSchedule } from "../../../services/Api/api";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getSchedulingValidation } from "../../../constants/schedulingValidation";
import DoneIcon from "@mui/icons-material/Done";
import { useSelector } from "react-redux";
import useApi from "hooks/useApi";
import useEventTracker from "services/EventTracker/useEventTracker";
import { useParams } from "react-router";

const moment = require("moment-timezone");

const selectAll = "Select All";
const removeAll = "Remove All";

function range(start, end) {
    return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
}

const useStyles = makeStyles((theme) => ({
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        height: 17,
        fontSize: 9,
        marginRight: 5,
        marginTop: 1,
    },
    inputLabel: {
        position: "absolute",
        left: 10,
        top: -10,
        paddingLeft: 2,
        paddingBottom: 3,
        // backgroundColor: "#FFFFFF",
        paddingRight: 45,
        zIndex: 1000,
    },
    select: {
        margin: "13px 0px",
    },
}));

const Days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const months = [selectAll, removeAll, "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const CustomSelect = (props) => {
    const classes = useStyles();
    const [field] = useField({
        name: props.name,
        type: "select",
        value: props.value,
    });
    return (
        <FormControl variant="standard" fullWidth className={classes.select}>
            <InputLabel className={classes.inputLabel}>{props.label}</InputLabel>
            <Select variant="standard" {...props} {...field} onChange={props.onChange}>
                {props.children}
            </Select>
        </FormControl>
    );
};

const AdvancedOptionsHourly = ({ touched, errors, schedule, values, handleChange, scheduleData }) => {
    const classes = useStyles();
    const [hourOptions, setHourOptions] = React.useState([...Array(24).keys()]);
    const onMonthsChange = (e) => {
        if (e.target.value.filter((el) => el === selectAll).length > 0) {
            e.target.value = months.filter((el) => el !== selectAll && el !== removeAll);
            document.elementFromPoint(0, 0).click();
        }
        if (e.target.value.filter((el) => el === removeAll).length > 0) {
            e.target.value = [];
            document.elementFromPoint(0, 0).click();
        }
        handleChange(e);
    };

    const onDaysChange = (e) => {
        if (e.target.value.filter((el) => el === selectAll).length > 0) {
            e.target.value = [...range(1, 31).map((el) => el.toString())];
            document.elementFromPoint(0, 0).click();
        }
        if (e.target.value.filter((el) => el === removeAll).length > 0) {
            e.target.value = [];
            document.elementFromPoint(0, 0).click();
        }
        handleChange(e);
    };
    return (
        <>
            {console.log(values)}
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormGroup>
                        <Field
                            error={touched.day && errors.hasOwnProperty("months")}
                            name="months"
                            as={CustomSelect}
                            value={values.months}
                            onChange={onMonthsChange}
                            multiple
                            fullWidth
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected?.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            margin="dense"
                            label="Months"
                            variant="outlined"
                            helperText={touched.month && errors?.month}
                        >
                            {months.map((el) => (
                                <MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Field>
                        {touched.months && errors.hasOwnProperty("months") ? (
                            <Typography className="errorText">{touched.months && errors?.months}</Typography>
                        ) : null}
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup>
                        <Field
                            error={touched.days && errors.hasOwnProperty("days")}
                            name="days"
                            as={CustomSelect}
                            value={values.days}
                            onChange={onDaysChange}
                            multiple
                            fullWidth
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            margin="dense"
                            label="Days"
                            variant="outlined"
                            helperText={touched.days && errors?.days}
                        >
                            {[selectAll, removeAll, ...range(1, 31).map((el) => el.toString())].map((el) => (
                                <MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Field>
                        {touched.days && errors.hasOwnProperty("days") && (
                            <Typography className="errorText">{touched.months && errors?.months}</Typography>
                        )}
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormGroup>
                        <Field
                            error={touched.hours && errors.hasOwnProperty("hours")}
                            name="hours"
                            as={CustomSelect}
                            value={values.hours}
                            onChange={handleChange}
                            multiple
                            fullWidth
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            margin="dense"
                            label="Hours"
                            variant="outlined"
                            helperText={touched.hours && errors?.hours}
                        >
                            {hourOptions.map((el) => (
                                <MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Field>
                        {touched.hours && errors.hasOwnProperty("hours") ? (
                            <Typography className="errorText">{touched.hours && errors?.hours}</Typography>
                        ) : null}
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup>
                        <Field
                            error={touched.minute && errors.hasOwnProperty("minute")}
                            name="minute"
                            as={TextField}
                            select
                            className={classes.select}
                            fullWidth
                            margin="dense"
                            label="Minute"
                            variant="outlined"
                            helperText={touched.minute && errors?.minute}
                        >
                            {[...range(0, 59)].map((el) => (
                                <MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Field>
                    </FormGroup>
                </Grid>
            </Grid>
        </>
    );
};

const AdvancedOptions = ({ touched, errors, schedule, values, handleChange, scheduleData }) => {
    const classes = useStyles();
    const [hourOptions, setHourOptions] = React.useState([]);
    const timezone = useSelector((state) => state?.users?.timezone);

    // const [isOpen, setIsOpen] = React.useState(false);
    // const inputref = useRef();
    React.useEffect(() => {
        if (values.interval !== "") {
            const hour = moment().tz("GMT").hours();
            const intervalOptions = schedule.filter((el) => el.schedule_frequency_level === 1);
            const selectedOption = intervalOptions.filter((el) => el.id === values.interval)[0];
            const scheduleFreq = selectedOption.schedule_frequency;
            let options = [];
            let i = 0;
            let optionHour = hour - 1;
            while (i < scheduleFreq) {
                optionHour += 1;
                if (optionHour >= 24) {
                    optionHour = 0;
                    options.push(optionHour);
                } else {
                    options.push(optionHour);
                }
                i += 1;
            }
            setHourOptions(options);
        }
    }, [values.interval]);

    // function triggerEvent(elem, event) {
    // 	var clickEvent = new Event(event); // Create the event.
    // 	elem.dispatchEvent(clickEvent); // Dispatch the event.
    // }

    const onMonthsChange = (e) => {
        if (e.target.value.filter((el) => el === selectAll).length > 0) {
            e.target.value = months.filter((el) => el !== selectAll && el !== removeAll);
            document.elementFromPoint(0, 0).click();
        }
        if (e.target.value.filter((el) => el === removeAll).length > 0) {
            e.target.value = [];
            document.elementFromPoint(0, 0).click();
        }
        // console.log(e);
        // console.log(document.getElementsByName("days")[0]);
        // setIsOpen(false);
        // e.target.close();
        // console.log(inputref);
        handleChange(e);
        // document.getElementById("months_field").blur();
        // document.getElementsByName("months")[0].blur();
        // document
        // 	.getElementsByName("days")[0]
        // 	.dispatchEvent(new MouseEvent("click", { bubbles: true, cancellable: true }));
        // var elem = document.getElementById("days_field");
        // triggerEvent(elem, "click");
        // inputref.current.close();
        // var tmp = document.createElement("input");
        // document.body.appendChild(tmp);
        // tmp.focus();
        // document.body.removeChild(tmp);
        // console.log(document.getElementById("months_field"));
    };

    const onDaysChange = (e) => {
        if (e.target.value.filter((el) => el === selectAll).length > 0) {
            e.target.value = [...range(1, 31).map((el) => el.toString())];
            document.elementFromPoint(0, 0).click();
        }
        if (e.target.value.filter((el) => el === removeAll).length > 0) {
            e.target.value = [];
            document.elementFromPoint(0, 0).click();
        }
        console.log(e);
        handleChange(e);
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormGroup>
                        <Field
                            error={touched.months && errors.hasOwnProperty("months")}
                            name="months"
                            id="months_field"
                            // inputProps={{ id: "months_field" }}
                            as={CustomSelect}
                            value={values.months}
                            onChange={onMonthsChange}
                            multiple
                            fullWidth
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected?.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            margin="dense"
                            label="Months"
                            variant="outlined"
                            // menuIsOpen={isOpen}
                            // inputProps={{ ref: inputref }}
                            helperText={touched.months && errors?.months}
                        >
                            {months.map((el) => (
                                <MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Field>
                        {touched.months && errors.hasOwnProperty("months") ? (
                            <Typography className="errorText">{touched.months && errors?.months}</Typography>
                        ) : null}
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup>
                        <Field
                            error={touched.days && errors.hasOwnProperty("days")}
                            name="days"
                            id="days_field"
                            as={CustomSelect}
                            value={values.days}
                            onChange={onDaysChange}
                            multiple
                            fullWidth
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            margin="dense"
                            label="Days"
                            variant="outlined"
                            helperText={touched.days && errors?.days}
                        >
                            {[selectAll, removeAll, ...range(1, 31).map((el) => el.toString())].map((el) => (
                                <MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Field>
                        {touched.days && errors.hasOwnProperty("days") && (
                            <Typography className="errorText">{touched.months && errors?.months}</Typography>
                        )}
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <FormGroup>
                        <Field
                            error={touched.interval && errors.hasOwnProperty("interval")}
                            name="interval"
                            as={TextField}
                            select
                            className={classes.select}
                            fullWidth
                            margin="dense"
                            label="Interval"
                            variant="outlined"
                            helperText={touched.interval && errors?.interval}
                        >
                            {schedule
                                .filter((el) => el.schedule_frequency_level === 1)
                                .map((sc) => (
                                    <MenuItem key={sc.id} value={sc.id}>
                                        {sc.schedule_frequency_label}
                                    </MenuItem>
                                ))}
                        </Field>
                    </FormGroup>
                </Grid>
                <Grid item xs={4}>
                    <FormGroup>
                        <Field
                            error={touched.start_hour && errors.hasOwnProperty("start_hour")}
                            name="start_hour"
                            as={TextField}
                            select
                            className={classes.select}
                            fullWidth
                            margin="dense"
                            defaultValue="13"
                            label="Hour"
                            variant="outlined"
                            helperText={touched.start_hour && errors?.start_hour}
                        >
                            {scheduleData?.start_hour !== null &&
                            hourOptions.filter((el) => el == scheduleData?.start_hour).length === 0 ? (
                                <MenuItem disabled={true} key={scheduleData?.start_hour} value={scheduleData?.start_hour}>
                                    {scheduleData?.start_hour}
                                </MenuItem>
                            ) : null}
                            {hourOptions.length === 0 ? (
                                <MenuItem value="" disabled>
                                    Empty List
                                </MenuItem>
                            ) : null}
                            {hourOptions.map((el) => (
                                <MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Field>
                    </FormGroup>
                </Grid>
                <Grid item xs={4}>
                    <FormGroup>
                        <Field
                            error={touched.minute && errors.hasOwnProperty("minute")}
                            name="minute"
                            as={TextField}
                            select
                            className={classes.select}
                            fullWidth
                            margin="dense"
                            label="Minute"
                            variant="outlined"
                            helperText={touched.minute && errors?.minute}
                        >
                            {[...range(0, 59)].map((el) => (
                                <MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Field>
                    </FormGroup>
                </Grid>
            </Grid>
        </>
    );
};

export const SchedulingFields = ({
    getLevel,
    touched,
    errors,
    schedule,
    values,
    handleChange,
    scheduleData,
    disableScheduling,
}) => {
    const classes = useStyles();
    const sortedSchedules = schedule;
    return (
        <>
            <FormGroup>
                <Field
                    error={touched.schedule && errors.hasOwnProperty("schedule")}
                    name="schedule"
                    as={TextField}
                    select
                    className={classes.select}
                    value={values.schedule}
                    disabled={disableScheduling}
                    fullWidth
                    margin="dense"
                    label="Select Schedule"
                    variant="outlined"
                    helperText={touched.schedule && errors?.schedule}
                >
                    {sortedSchedules?.map((sc) => (
                        <MenuItem key={sc.id} value={sc.id}>
                            {sc.schedule_frequency_label}
                        </MenuItem>
                    ))}
                </Field>
            </FormGroup>
            {values.schedule !== "" ? (
                <>
                    {getLevel(values.schedule) === 2 ? (
                        <FormGroup>
                            <Field
                                error={touched.hour && errors.hasOwnProperty("hour")}
                                name="hour"
                                as={TextField}
                                select
                                className={classes.select}
                                disabled={disableScheduling}
                                fullWidth
                                margin="dense"
                                label="Hour"
                                variant="outlined"
                                helperText={touched.hour && errors?.hour}
                            >
                                {[...Array(24).keys()].map((el) => (
                                    <MenuItem key={el} value={el}>
                                        {el}
                                    </MenuItem>
                                ))}
                            </Field>
                        </FormGroup>
                    ) : null}
                    {getLevel(values.schedule) === 3 ? (
                        <>
                            <FormGroup>
                                <Field
                                    error={touched.day && errors.hasOwnProperty("day")}
                                    name="day"
                                    as={TextField}
                                    select
                                    disabled={disableScheduling}
                                    className={classes.select}
                                    fullWidth
                                    margin="dense"
                                    label="Day"
                                    variant="outlined"
                                    helperText={touched.day && errors?.day}
                                >
                                    {Days.map((el) => (
                                        <MenuItem key={el} value={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    error={touched.hour && errors.hasOwnProperty("hour")}
                                    name="hour"
                                    as={TextField}
                                    select
                                    className={classes.select}
                                    fullWidth
                                    disabled={disableScheduling}
                                    margin="dense"
                                    label="Hour"
                                    variant="outlined"
                                    helperText={touched.hour && errors?.hour}
                                >
                                    {[...Array(24).keys()].map((el) => (
                                        <MenuItem key={el} value={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </FormGroup>
                        </>
                    ) : null}
                    {getLevel(values.schedule) === 4 ? (
                        <>
                            <FormGroup>
                                <Field
                                    error={touched.day && errors.hasOwnProperty("day")}
                                    name="day"
                                    as={TextField}
                                    select
                                    className={classes.select}
                                    disabled={disableScheduling}
                                    fullWidth
                                    margin="dense"
                                    label="Days"
                                    variant="outlined"
                                    helperText={touched.day && errors?.day}
                                >
                                    {[...range(1, 31)].map((el) => (
                                        <MenuItem key={el} value={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    error={touched.hour && errors.hasOwnProperty("hour")}
                                    name="hour"
                                    as={TextField}
                                    select
                                    className={classes.select}
                                    disabled={disableScheduling}
                                    fullWidth
                                    margin="dense"
                                    label="Hour"
                                    variant="outlined"
                                    helperText={touched.hour && errors?.hour}
                                >
                                    {[...Array(24).keys()].map((el) => (
                                        <MenuItem key={el} value={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </FormGroup>
                        </>
                    ) : null}
                    {getLevel(values.schedule) === 5 ? (
                        <>
                            <AdvancedOptions
                                touched={touched}
                                errors={errors}
                                schedule={schedule}
                                values={values}
                                handleChange={handleChange}
                                scheduleData={scheduleData}
                            />
                        </>
                    ) : null}
                    {getLevel(values.schedule) === 6 && (
                        <>
                            <AdvancedOptionsHourly
                                touched={touched}
                                errors={errors}
                                schedule={schedule}
                                values={values}
                                handleChange={handleChange}
                                scheduleData={scheduleData}
                            />
                        </>
                    )}
                </>
            ) : null}
        </>
    );
};

const SendDataToParent = (props) => {
    const { values } = useFormikContext();
    React.useEffect(() => {
        props.handleSubmit(values, null);
    }, [values]);
    return null;
};

export const SchedulingComponent = (props) => {
    const [initialValues, setInitialValues] = React.useState({
        manualRun: false,
        schedule: "",
        hour: "",
        start_hour: "",
        day: "",
        months: [],
        days: [],
        interval: "",
        minute: "",
        hours: [],
    });
    React.useEffect(() => {
        console.log(initialValues);
    }, [initialValues]);
    const EventTracker = useEventTracker();
    const { dataSourceSlug } = useParams();

    React.useEffect(() => {
        if (props.refreshSchedule !== undefined) props?.refreshSchedule(props.templateId);
    }, [initialValues]);

    const getLevel = (id) => {
        const el = schedule.find((el) => el.id == id);
        if (el == undefined) {
            return -1;
        }
        return el.schedule_frequency_level;
    };
    const validationObject = getSchedulingValidation(getLevel);
    const validationSchema = object(validationObject);

    // const [schedule, setSchedule] = React.useState([]);
    // const [scheduleIsLoading, setScheduleIsLoading] = React.useState(false);

    // getting schedules using useApi
    const [schedule, scheduleIsLoading] = useApi(
        React.useMemo(
            () => ({
                initialValue: [],
                module: "schedule",
                method: "getSchedules",
                data: {
                    module: props.module !== undefined ? props.module : null,
                    data: props.moduleData !== undefined ? props.moduleData : null,
                },
            }),
            []
        )
    );

    React.useEffect(() => {
        if (schedule.length !== 0) {
            if (props.defaultScheduleOptionId) {
                setInitialValues((values) => ({ ...values, schedule: props.defaultScheduleOptionId }));
            }
            if (props.scheduleData !== null && props.scheduleData !== undefined) {
                const data = props.scheduleData;
                let objectToInsert = {};
                if (data?.cron_string !== undefined) {
                    objectToInsert = {
                        manualRun: data.cron_string === null ? true : false,
                    };
                } else {
                    objectToInsert = {
                        manualRun: Object.keys(data).length === 0 ? true : false,
                    };
                }
                if (Object.keys(data).length !== 0) {
                    objectToInsert = {
                        ...objectToInsert,
                        schedule: data === null ? "" : data.schedule_option_id,
                        day: data.day === null ? "" : data.day,
                        days: data.days === null ? [] : data.days.split(","),
                        hour: data.hour === null ? "" : data.hour,
                        months: data.months === null ? [] : data.months.split(","),
                        interval: data.schedule_interval === null ? "" : data.schedule_interval,
                        minute: data.start_min === null ? "" : data.start_min,
                        day: data.day === null ? (data.week_day === null ? "" : data.week_day) : data.day,
                        hour: data.hour === null ? (data.start_hour === null ? "" : data.start_hour) : data.hour,
                        start_hour: data.start_hour === null ? "" : data.start_hour,
                        hours:
                            data.hours === null || data.hours === undefined ? [] : data.hours.split(",").map((el) => Number(el)),
                    };
                }
                setInitialValues((values) => ({
                    ...values,
                    ...objectToInsert,
                }));
                if (props.hideActionButtons === true) handleSubmit(objectToInsert);
            }
        }
    }, [schedule]);

    const handleSubmit = (values, helpers) => {
        let finalObject = {
            scheduling: {
                manual_run: values.manualRun ? 1 : 0,
            },
        };
        if (!values.manualRun) {
            finalObject.scheduling.schedule = {};
            finalObject.scheduling.schedule.schedule_option_id = Number(values.schedule);
            finalObject.scheduling.schedule.schedule_data = {};
            if (getLevel(values.schedule) === 2) {
                finalObject.scheduling.schedule.schedule_data.hour = Number(values.hour);
            } else if (getLevel(values.schedule) === 3) {
                finalObject.scheduling.schedule.schedule_data.week_day = values.day;
                finalObject.scheduling.schedule.schedule_data.hour = Number(values.hour);
            } else if (getLevel(values.schedule) === 4) {
                finalObject.scheduling.schedule.schedule_data.day = Number(values.day);
                finalObject.scheduling.schedule.schedule_data.hour = Number(values.hour);
            } else if (getLevel(values.schedule) === 5) {
                finalObject.scheduling.schedule.schedule_data.months = values.months;
                finalObject.scheduling.schedule.schedule_data.days = values.days.map((el) => Number(el));
                finalObject.scheduling.schedule.schedule_data.schedule_interval = Number(values.interval);
                finalObject.scheduling.schedule.schedule_data.start_hour = Number(values.start_hour);
                finalObject.scheduling.schedule.schedule_data.start_min = Number(values.minute);
            } else if (getLevel(values.schedule) === 6) {
                finalObject.scheduling.schedule.schedule_data.months = values.months;
                finalObject.scheduling.schedule.schedule_data.days = values.days.map((el) => Number(el));
                finalObject.scheduling.schedule.schedule_data.hours = values.hours.map((el) => Number(el));
                finalObject.scheduling.schedule.schedule_data.start_min = Number(values.minute);
            }
            if (Object.keys(finalObject.scheduling.schedule.schedule_data).length === 0) {
                delete finalObject.scheduling.schedule.schedule_data;
            }
        }
        if (props.editMode) {
            if (props?.patchScheduling) props?.patchScheduling(finalObject);
            if (props.mergeStepData) {
                props?.mergeStepData("scheduling", { ...finalObject.scheduling });
            }
            if (props?.handleSubmit) props.handleSubmit(finalObject);

            // console.log(finalObject)
        } else if (props.multiple) {
            props.handleSaveSingleSchedule(finalObject, helpers.errors, props.templateId);
        } else {
            if (props?.mergeStepData) props.mergeStepData("scheduling", { ...finalObject.scheduling });
            if (props.isLastStep === undefined || !props.isLastStep) {
                EventTracker.trackSelfDescribingEvent("configuredReport_add_scheduling_added", {
                    datasourceSlug: dataSourceSlug,
                    scheduling_data: finalObject,
                });
                props.handleNext();
            } else {
                props.handleSubmit(finalObject);
            }
        }
    };

    if (scheduleIsLoading) {
        return (
            <Grid container justifyContent="center">
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values, helpers) => handleSubmit(values, helpers)}
                enableReinitialize={true}
            >
                {({
                    values,
                    errors,
                    isSubmitting,
                    isValidating,
                    setErrors,
                    touched,
                    handleChange,
                    validateForm,
                    setTouched,
                    handleSubmit,
                }) => (
                    <Form>
                        {props.hideActionButtons === true && <SendDataToParent handleSubmit={handleSubmit} />}
                        {props.multiple ? (
                            <Grid container justifyContent="flex-end">
                                <div
                                    id={props.templateId}
                                    ref={props.addToRefs}
                                    style={{ display: "none" }}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    save
                                </div>
                            </Grid>
                        ) : null}
                        <FormGroup>
                            <FormControlLabel
                                style={{ fontSize: 14 }}
                                control={
                                    <Field
                                        as={Switch}
                                        name="manualRun"
                                        // onChange={(e) => handleFieldChange(handleChange, e)}
                                        disabled={props.disableScheduling}
                                        color="primary"
                                        checked={values.manualRun}
                                        onClick={(e) => (!e.target.value ? setErrors({}) : null)}
                                    />
                                }
                                label="Manual Run Only"
                            />
                        </FormGroup>
                        {values.manualRun ? null : scheduleIsLoading ? (
                            <CircularProgress />
                        ) : (
                            <SchedulingFields
                                getLevel={getLevel}
                                touched={touched}
                                errors={errors}
                                values={values}
                                handleChange={handleChange}
                                schedule={schedule}
                                scheduleData={props.scheduleData}
                                disableScheduling={props.disableScheduling}
                            />
                        )}
                        {props.editMode && props.hideActionButtons !== true ? (
                            <>
                                <hr style={{ margin: "25px 0 5px 0" }} />
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            disabled={
                                                props.scheduleIsLoading ||
                                                (props.editMode && props.disableScheduling) ||
                                                props.isSubmitting
                                            }
                                            variant="contained"
                                            type="submit"
                                        >
                                            {props.scheduleIsLoading || props.isSubmitting ? (
                                                <CircularProgress style={{ marginRight: 10, color: "white" }} size={16} />
                                            ) : null}
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ) : !props.multiple || props.multiple === undefined ? (
                            <>
                                {props.hideActionButtons === true ? null : (
                                    <>
                                        <hr style={{ margin: "25px 0 5px 0" }} />
                                        <Grid container justifyContent="center" spacing={2}>
                                            <Grid item>
                                                <Button variant="outlined" color="primary" onClick={props.handleBack}>
                                                    Back
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={props.isSubmitting}
                                                    onClick={() => console.log(errors)}
                                                >
                                                    {props.isSubmitting ? (
                                                        <CircularProgress style={{ marginRight: 10, color: "white" }} size={16} />
                                                    ) : null}
                                                    {props.isLastStep ? "Submit" : "Next"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </>
                        ) : null}
                    </Form>
                )}
            </Formik>
        </>
    );
};
