import { Typography, Grid, IconButton, Tooltip } from "@mui/material";
import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { nodeIconMapper } from "components/Orchestration/AddOrchestration/common/iconMapper";
import { useReactFlow } from "reactflow";
import { v4 as uuidv4 } from "uuid";
import flowConfig from "../../common/FlowConfig";
import { CloseOutlined } from "@mui/icons-material";
import { nodesData } from "../../index";

// const nodesData = [
//     {
//         category: "DataChannel Nodes",
//         nodes: [
//             {
//                 name: "Datasource",
//                 slug: "datasourceNode",
//                 id: 1,
//             },
//             {
//                 name: "SQL Transformation",
//                 slug: "sqlTransformationNode",
//                 id: 2,
//             },
//             {
//                 name: "DBT Transformation",
//                 slug: "dbtTransformationNode",
//                 id: 3,
//             },
//             {
//                 name: "Reverse Connector",
//                 slug: "reverseConnectorNode",
//                 id: 4,
//             },
//         ],
//     },
//     {
//         category: "Action Nodes",
//         nodes: [
//             {
//                 name: "Decision Node",
//                 slug: "decisionNode",
//                 id: 4,
//             },
//             {
//                 name: "Tableau Node",
//                 slug: "tableauRefreshNode",
//                 id: 6,
//             },
//         ],
//     },
// ];

export default memo(({ data, isConnectable, id }) => {
    const { setNodes, setEdges } = useReactFlow();

    const handleNodeClick = (selectedNode) => {
        const newNodeId = uuidv4();
        setNodes((nodes) => {
            nodes.forEach((node) => {
                if (node.id === id) {
                    node.type = selectedNode.slug;
                    node.data = {
                        isNodeToolbarOpen: true,
                        borderColor: flowConfig.defaultNodeBorderColor,
                    };
                    node.id = newNodeId;
                }
            });
            return nodes;
        });
        setEdges((edges) => {
            edges.forEach((edge) => {
                if (edge.target === id) {
                    edge.target = newNodeId;
                    delete edge["animated"];
                }
                if (edge.source === id) {
                    edge.source = newNodeId;
                }
            });
            return edges;
        });
    };

    /**
     * remove nodepicker node from the canvas and delete any
     * edge connected to this node
     */
    const handleClose = () => {
        setNodes((nodes) => nodes.filter((node) => node.id !== id));
        setEdges((edges) => edges.filter((edge) => edge.target !== id));
    };

    return (
        <div style={{ border: "0.823px solid #E7E7E7", borderRadius: 5, width: 350 }}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    style={{ background: "#F5F5F5", position: "relative" }}
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid style={{ position: "absolute", top: 5, right: 5 }} item>
                        <Tooltip title="Close Node Picker Node.">
                            <IconButton onClick={handleClose}>
                                <CloseOutlined />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            style={{ padding: "0px 0px", marginTop: 15, textAlign: "center" }}
                            className="size-14 blackText semiBoldText"
                        >
                            Select A Node
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ padding: "0px 0px", textAlign: "center" }} className="size-12 grayText semiBoldText">
                            Plug any other node here.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ background: "white", margin: "10px 20px", borderRadius: 10 }}>
                            {nodesData.map((category) => (
                                <div>
                                    <Grid container>
                                        {category.nodes.map((node) => (
                                            <Grid
                                                item
                                                xs={12}
                                                className="dndnode input hoverable"
                                                onClick={() => handleNodeClick(node)}
                                                draggable
                                                style={{ borderBottom: "1px solid #F5F5F5" }}
                                                alignItems="center"
                                            >
                                                <Grid container style={{ margin: "5px 10px" }}>
                                                    <img
                                                        style={{ height: 30, width: 30 }}
                                                        src={nodeIconMapper[node.slug]}
                                                        alt="node icon"
                                                    />
                                                    <Typography style={{ padding: 5, color: "#929EFF", fontSize: 13 }}>
                                                        {node.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Handle
                type="target"
                position={Position.Left}
                id="selecter-node-target"
                isConnectable={isConnectable}
                style={{ height: 10, width: 10, backgroundColor: "#FD9567" }}
            />
        </div>
    );
});
