import { IoMdInformationCircleOutline } from "react-icons/io";
import IconButton from "@mui/material/IconButton";
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";

/**
 * `NodeTooltip` is a functional component that displays a tooltip with a given title.
 * The tooltip includes an information icon button and is displayed at the top-start of the parent component.
 * The tooltip also has a zoom transition effect.
 *
 * @component
 * @param {string} title The title to be displayed in the tooltip.
 * @returns {React.Element} The `NodeTooltip` component.
 */
function NodeTooltip(props) {
    return (
        <>
            <Tooltip
                title={props.title}
                arrow
                placement="top-start"
                TransitionComponent={Zoom}
                sx={{
                    margin: "1.2rem 0 0 0.3rem",
                }}
                {...props}
            >
                <IconButton>
                    <IoMdInformationCircleOutline style={{ color: "#8b8b8b", fontSize: "1.5rem", margin: "0" }} />
                </IconButton>
            </Tooltip>
        </>
    );
}

export default NodeTooltip;
