import { Grid, Paper, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import Pagination from "@mui/material/Pagination";
import Image from "components/common/UIComponents/Image";
import Config from "constants/config";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_SELECTED_REVERSE_CONNECTOR, SET_SELECTED_REVERSE_CRED } from "redux/actionTypes/actionTypes";
import { fetchReverseConnectorsList } from "redux/actions/reverseConnectorsActions";

const useStyles = makeStyles((theme) => ({
    font16: {
        fontSize: 15,
    },
    font12: {
        fontSize: 12,
        color: "#9d9d9d",
    },
    border: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "10px",
        margin: 5,
        cursor: "pointer",
    },
    selectedBorder: {
        border: `1px solid #FD9567 !important`,
        borderRadius: "10px",
        margin: 5,
        cursor: "pointer",
        background:
            "linear-gradient(90deg, rgba(253,149,103,0.09007352941176472) 0%, rgba(253,149,103,0.26094187675070024) 100%)",
    },
}));

export default function SelectReverseConnector({ selectedDatasourceSlug, onDatasourceSelect }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchReverseConnectorsList());
    }, []);

    const reverseConnectors = useSelector((state) => state.reverseConnectors);

    const [filter, setFilter] = React.useState("");
    const [page, setPage] = React.useState(1);
    const source_per_page = 9;

    const getFilteredDatasources = (dataSources) => {
        const filtered = dataSources.filter(
            (el) => el.slug.toLowerCase().includes(filter) || el.display_label.toLowerCase().includes(filter)
        );
        return filtered;
    };

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleSelectDatasource = (reverseConnector) => {
        onDatasourceSelect(reverseConnector);
    };

    const calPageCount = (count, source_per_page) => {
        let remainder = count % source_per_page;
        if (remainder === 0) {
            return parseInt(count / source_per_page);
        } else {
            return parseInt(count / source_per_page) + 1;
        }
    };

    if (reverseConnectors.reverseConnectorsIsLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: 250 }}>
                <CircularProgress />
            </Grid>
        );
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography
                    variant="h5"
                    classes="blackText boldText"
                    style={{ fontSize: 18, textAlign: "center", fontWeight: 600 }}
                >
                    Choose a reverse connector you want to configure:
                </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="center" style={{ marginTop: 10 }}>
                <Grid item xs={6}>
                    <TextField
                        size="small"
                        variant="outlined"
                        label="Search"
                        fullWidth
                        onChange={(e) => {
                            setPage(1);
                            setFilter(e.target.value.toLowerCase());
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent={"center"} style={{ marginTop: 10 }}>
                <Grid item xs={12} container justifyContent={"center"}>
                    <Grid xs={12} item>
                        <Typography
                            variant="body1"
                            style={{ fontSize: 12, color: "#626262", textAlign: "center", fontWeight: 500 }}
                            align="center"
                        >
                            Configured Data Destinations
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
                <Grid container spacing={4}>
                    <AnimatePresence mode="popLayout" exitBeforeEnter>
                        {getFilteredDatasources(reverseConnectors?.reverseConnectors)
                            .filter((dataDestination) => dataDestination.no_of_configured_syncs > 0)
                            .slice(source_per_page * (page - 1), source_per_page * page)
                            .map((reverseConnector) => (
                                <Grid item xs={4}>
                                    <Paper
                                        elevation={0}
                                        onClick={() => handleSelectDatasource(reverseConnector)}
                                        component={motion.div}
                                        initial={{ y: -100, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        whileHover={{ scale: 1.03, border: "1px solid #FD9567", cursor: "pointer" }}
                                        style={{
                                            borderRadius: 10,
                                            display: "flex",
                                            alignItems: "center",
                                            height: "100%",
                                            border: selectedDatasourceSlug === reverseConnector.slug ? "1px solid #FD9567" : null,
                                            boxShadow:
                                                "0px 100px 80px rgba(12, 44, 108, 0.07), 0px 22.3363px 17.869px rgba(12, 44, 108, 0.0417275), 0px 6.6501px 5.32008px rgba(12, 44, 108, 0.0282725)",
                                        }}
                                    >
                                        <Grid container justifyContent="center" style={{ padding: "20px 10px" }}>
                                            <Grid item>
                                                <Image
                                                    style={{ height: 65, width: 65 }}
                                                    src={`${Config.imageBaseUrl}images/connectors/${reverseConnector.datasource.slug}.png`}
                                                    alt={reverseConnector.slug}
                                                    name={reverseConnector.display_label}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    style={{ textAlign: "center", marginTop: 15, fontSize: 14, color: "#152945" }}
                                                >
                                                    {reverseConnector.display_label}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))}
                    </AnimatePresence>
                    <Grid container justifyContent="center" style={{ margin: "30px 0px" }}>
                        <Pagination
                            count={calPageCount(
                                getFilteredDatasources(reverseConnectors?.reverseConnectors)?.filter(
                                    (dataDestination) => dataDestination.no_of_configured_syncs > 0
                                ).length,
                                source_per_page
                            )}
                            color="primary"
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
