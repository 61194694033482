import { Typography, Grid } from "@mui/material";
import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import Start from "assets/images/Orchestration/start.svg";
import HandleWrapper from "./common/HandleWrapper";

export default memo(({ data, isConnectable, id }) => {
    return (
        <div style={{ background: "white", border: `1px solid ${data?.borderColor}`, borderRadius: 5 }}>
            <Grid container style={{ width: 100 }}>
                <Grid item xs={5} container justifyContent="center" style={{ borderRight: "0.823px solid #E7E7E7" }}>
                    <img src={Start} alt="start icon" style={{ padding: "10px 0px" }} />
                </Grid>
                <Grid item xs={7} container justifyContent="center">
                    <Typography style={{ padding: "10px 0px" }} className="size-12 blackText">
                        Start
                    </Typography>
                </Grid>
            </Grid>
            {/* <Handle type="source" position={Position.Right} id="start-node-source" isConnectable={isConnectable} /> */}
            <HandleWrapper
                nodeId={id}
                handleId="start-node-source"
                isConnectable={isConnectable}
                tooltipTitle="Connect to the first node."
                position={Position.Right}
            />
        </div>
    );
});
