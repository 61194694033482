import React from "react";
import withStyles from "@mui/styles/withStyles";
// import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { Fade, Grid, Modal, Typography, Button } from "@mui/material";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
const useStyles = makeStyles((theme) => ({
    cross: {
        // margin: "15px 20px",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 4),
        borderRadius: "16px",
        border: "1px solid #E40D0D",
        position: "relative",
    },
    icon: {
        paddingRight: "36px",
    },
}));

const StyledMenu = withStyles({
    paper: {
        border: "1px solid rgb(253, 149, 103 , 0.4)",
        borderRadius: "7px",
        minWidth: "160px",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:focus": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const NewTabIcon = () => {
    return <LaunchIcon color="primary" style={{ fontSize: 14 }} />;
};

const OrchestartionNodeContextMenu = ({ data, handleClose }) => {
    const classes = useStyles();

    const [warningModelOpen, setWarningModalOpen] = React.useState(false);
    const [swapWarningModelOpen, setSwapWarningModalOpen] = React.useState(false);

    const handleCloseDelete = () => {
        setWarningModalOpen(false);
    };
    const handleSwapModalClose = () => {
        setSwapWarningModalOpen(false);
    };
    return (
        <div>
            <Modal className={classes.modal} open={warningModelOpen} onClose={handleClose}>
                <Fade in={warningModelOpen}>
                    <div className={classes.paper}>
                        <Grid className={classes.cross}></Grid>
                        <Grid>
                            <Typography variant="h3">Are you sure you want to delete this node?</Typography>
                            <Typography variant="h3">
                                This action cannot be undone and all selected configration will be lost.
                            </Typography>
                            <Grid container justifyContent="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        data.onDelete();
                                        handleCloseDelete();
                                        handleClose();
                                    }}
                                >
                                    Delete
                                </Button>
                                <Button
                                    style={{ marginLeft: 10 }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => handleCloseDelete()}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                        <CloseIcon
                            className="hoverable"
                            style={{ position: "absolute", margin: 12, top: 0, right: 0 }}
                            onClick={() => handleCloseDelete()}
                            fontSize="small"
                        />
                    </div>
                </Fade>
            </Modal>

            <Modal className={classes.modal} open={swapWarningModelOpen} onClose={handleSwapModalClose}>
                <Fade in={swapWarningModelOpen}>
                    <div className={classes.paper}>
                        <Grid className={classes.cross}></Grid>
                        <Grid>
                            <Typography variant="h3">Are you sure you want to swap this node?</Typography>
                            <Typography variant="h3">
                                This action cannot be undone and all selected configration will be lost.
                            </Typography>
                            <Grid container justifyContent="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        data.onSwap();
                                        handleClose();
                                        handleSwapModalClose();
                                    }}
                                >
                                    Swap
                                </Button>
                                <Button
                                    style={{ marginLeft: 10 }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={handleSwapModalClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                        <CloseIcon
                            className="hoverable"
                            style={{ position: "absolute", margin: 12, top: 0, right: 0 }}
                            onClick={() => handleSwapModalClose()}
                            fontSize="small"
                        />
                    </div>
                </Fade>
            </Modal>
            <StyledMenuItem
                onClick={(e) => {
                    e.stopPropagation();
                    data.onEdit();
                    handleClose();
                }}
            >
                <ListItemText
                    primary={
                        <Typography style={{ paddingRight: 20 }} variant="h6">
                            Edit
                        </Typography>
                    }
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="new tab" size="large">
                        <EditIcon style={{ fontSize: 16 }} />
                    </IconButton>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem
                onClick={(e) => {
                    e.stopPropagation();
                    setWarningModalOpen(true);
                }}
            >
                <ListItemText
                    primary={
                        <Typography style={{ paddingRight: 20 }} variant="h6">
                            Delete
                        </Typography>
                    }
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="new tab" size="large">
                        <DeleteIcon style={{ fontSize: 16 }} />
                    </IconButton>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem
                onClick={(e) => {
                    e.stopPropagation();
                    setSwapWarningModalOpen(true);
                }}
            >
                <ListItemText
                    primary={
                        <Typography style={{ paddingRight: 20 }} variant="h6">
                            Swap
                        </Typography>
                    }
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="new tab" size="large">
                        <SwapCallsIcon style={{ fontSize: 16 }} />
                    </IconButton>
                </ListItemSecondaryAction>
            </StyledMenuItem>
        </div>
    );
};

const componentTOMenuMapper = {
    datasource_list: (data) => (
        <div>
            <StyledMenuItem component={Link} to={`/datasource/${data.datasourceSlug}`}>
                <ListItemText primary={<Typography variant="h6">Datasource Detail</Typography>} />
                <ListItemSecondaryAction>
                    <Link to={`/datasource/${data.datasourceSlug}`} target="_blank">
                        <IconButton edge="end" aria-label="new tab" size="large">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem component={Link} to={`/datasource/${data.datasourceSlug}?tab=configured-reports`}>
                <ListItemText primary={<Typography variant="h6">Configured Report</Typography>} />
                <ListItemSecondaryAction>
                    <Link to={`/datasource/${data.datasourceSlug}?tab=configured-reports`} target="_blank">
                        <IconButton edge="end" aria-label="new tab" size="large">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem component={Link} to={`/datasource/${data.datasourceSlug}`}>
                <ListItemText primary={<Typography variant="h6">Credentials</Typography>} />
                <ListItemSecondaryAction>
                    <Link to={`/datasource/${data.datasourceSlug}`} target="_blank">
                        <IconButton edge="end" aria-label="new tab" size="large">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
        </div>
    ),
    configured_report: (data) => (
        <div>
            <StyledMenuItem
                component={Link}
                to={`/datasource/${data?.datasourceSlug}/detail/editreport/${data.row?.original?.id}`}
            >
                <ListItemText primary={<Typography variant="h6">View/Edit Details</Typography>} />
                <ListItemSecondaryAction>
                    <Link to={`/datasource/${data?.datasourceSlug}/detail/editreport/${data.row?.original?.id}`} target="_blank">
                        <IconButton edge="end" aria-label="new tab" size="large">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem
                component={Link}
                to={`/datasource/${data?.datasourceSlug}/detail/editreport/${data.row?.original?.id}?tab=last_run`}
            >
                <ListItemText primary={<Typography variant="h6">Last Run Status</Typography>} />
                <ListItemSecondaryAction>
                    <Link
                        to={`/datasource/${data?.datasourceSlug}/detail/editreport/${data.row?.original?.id}?tab=last_run`}
                        target="_blank"
                    >
                        <IconButton edge="end" aria-label="new tab" size="large">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem
                component={Link}
                to={`/datasource/${data?.datasourceSlug}/detail/editreport/${data.row?.original?.id}?tab=change_history`}
            >
                <ListItemText primary={<Typography variant="h6">Change History</Typography>} />
                <ListItemSecondaryAction>
                    <Link
                        to={`/datasource/${data?.datasourceSlug}/detail/editreport/${data.row?.original?.id}?tab=change_history`}
                        target="_blank"
                    >
                        <IconButton edge="end" aria-label="new tab" size="large">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem
                component={Link}
                to={`/datasource/${data?.datasourceSlug}/report/new?configured_report_id=${data.row?.original?.id}`}
            >
                <ListItemText primary={<Typography variant="h6">Copy</Typography>} />
                <ListItemSecondaryAction>
                    <Link
                        to={`/datasource/${data?.datasourceSlug}/report/new?configured_report_id=${data.row?.original?.id}`}
                        target="_blank"
                    >
                        <IconButton edge="end" aria-label="new tab" size="large">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
        </div>
    ),
    orchestration_nodes: (data, handleClose) => <OrchestartionNodeContextMenu data={data} handleClose={handleClose} />,
    configured_syncs: (data) => (
        <div>
            <StyledMenuItem
                component={Link}
                to={`/reverse-connectors/${data?.reverseConnectorSlug}/sync/${data.row.original.id}`}
            >
                <ListItemText primary={<Typography variant="h6">Edit Sync</Typography>} />
                <ListItemSecondaryAction>
                    <Link to={`/reverse-connectors/${data?.reverseConnectorSlug}/sync/${data.row.original.id}`} target="_blank">
                        <IconButton edge="end" aria-label="new tab" size="large">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
        </div>
    ),
    orchestration: (data) => (
        <div>
            <StyledMenuItem component={Link} to={`/orchestrations/${data.row?.original?.id}`}>
                <ListItemText
                    primary={
                        <Typography style={{ paddingRight: 20 }} variant="h6">
                            Edit Details
                        </Typography>
                    }
                />
                <ListItemSecondaryAction>
                    <Link to={`/orchestrations/${data.row?.original?.id}`} target="_blank">
                        <IconButton edge="end" aria-label="new tab" size="large">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
            <StyledMenuItem component={Link} to={`/orchestrations/${data.row?.original?.id}?tab=execution-history`}>
                <ListItemText
                    primary={
                        <Typography style={{ paddingRight: 20 }} variant="h6">
                            Run Status
                        </Typography>
                    }
                />
                <ListItemSecondaryAction>
                    <Link to={`/orchestrations/${data.row?.original?.id}?tab=execution-history`} target="_blank">
                        <IconButton edge="end" aria-label="new tab" size="large">
                            <NewTabIcon />
                        </IconButton>
                    </Link>
                </ListItemSecondaryAction>
            </StyledMenuItem>
        </div>
    ),
};

const getMenuForComponent = (componentName, state, handleClose, data) => {
    if (typeof componentTOMenuMapper[componentName] === typeof undefined) {
        return null;
    }
    return (
        <StyledMenu
            id="customized-menu"
            anchorReference="anchorPosition"
            anchorPosition={
                state.mouseY !== null && state.mouseX !== null ? { top: state.mouseY, left: state.mouseX } : undefined
            }
            open={state.mouseY !== null}
            keepMounted
            onClose={handleClose}
        >
            {componentTOMenuMapper[componentName](data, handleClose)}
        </StyledMenu>
    );
};

const initialState = {
    mouseX: null,
    mouseY: null,
};

export default function ContextMenu(props) {
    const [state, setState] = React.useState(initialState);

    const handleClick = (event) => {
        event.preventDefault();
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleClose = () => {
        setState(initialState);
    };

    const childrenWithProps = React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                "aria-controls": "simple-menu",
                "aria-haspopup": "true",
                onContextMenu: handleClick,
                color: "primary",
            });
        }
        return child;
    });

    if (typeof componentTOMenuMapper[props.componentName] === typeof undefined) {
        return props.children;
    }

    return (
        <>
            {childrenWithProps}
            {getMenuForComponent(props.componentName, state, handleClose, props.data)}
        </>
    );
}
